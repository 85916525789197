import { black, white, red500, deepPurple500, green500, pink500, blue500, indigo900, pink100, cyan500 } from "material-ui/styles/colors";

const theme = [
    //Black
    {
        palette: {
            textColor: black,
            primary1Color: black,
            primary2Color: black,
            accent1Color: black,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: black
        },
        timePicker: {
            headerColor: black
        },
        toggle: {
            thumbOnColor: black
        }
    }, //RED
    {
        palette: {
            textColor: black,
            primary1Color: red500,
            primary2Color: red500,
            accent1Color: red500,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: red500
        },
        timePicker: {
            headerColor: red500
        },
        toggle: {
            thumbOnColor: red500
        }
    }, //Purple
    {
        palette: {
            textColor: black,
            primary1Color: deepPurple500,
            primary2Color: deepPurple500,
            accent1Color: deepPurple500,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: deepPurple500
        },
        timePicker: {
            headerColor: deepPurple500
        },
        toggle: {
            thumbOnColor: deepPurple500
        }
    }, //green
    {
        palette: {
            textColor: black,
            primary1Color: green500,
            primary2Color: green500,
            accent1Color: green500,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: green500
        },
        timePicker: {
            headerColor: green500
        },
        toggle: {
            thumbOnColor: green500
        }
    }, //pink
    {
        palette: {
            textColor: black,
            primary1Color: pink500,
            primary2Color: pink500,
            accent1Color: pink500,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: pink500
        },
        timePicker: {
            headerColor: pink500
        },
        toggle: {
            thumbOnColor: pink500
        }
    },
    //blue
    {
        palette: {
            textColor: black,
            primary1Color: blue500,
            primary2Color: blue500,
            accent1Color: blue500,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: blue500
        },
        timePicker: {
            headerColor: blue500
        },
        toggle: {
            thumbOnColor: blue500
        }
    },
    //indigo
    {
        palette: {
            textColor: black,
            primary1Color: indigo900,
            primary2Color: indigo900,
            accent1Color: indigo900,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: indigo900
        },
        timePicker: {
            headerColor: indigo900
        },
        toggle: {
            thumbOnColor: indigo900
        }
    }, //Light pink
    {
        palette: {
            textColor: black,
            primary1Color: pink100,
            primary2Color: pink100,
            accent1Color: pink100,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: pink100
        },
        timePicker: {
            headerColor: pink100
        },
        toggle: {
            thumbOnColor: pink100
        }
    }, //Cyan
    {
        palette: {
            textColor: black,
            primary1Color: cyan500,
            primary2Color: cyan500,
            accent1Color: cyan500,
            pickerHeaderColor: white,
            alternateTextColor: white
        },
        AppBar: {
            height: 20
        },
        datePicker: {
            headerColor: cyan500
        },
        timePicker: {
            headerColor: cyan500
        },
        toggle: {
            thumbOnColor: cyan500
        }
    }
];

export default theme;
