import React from "react";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import CircularProgress from "material-ui/CircularProgress";
import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";

import withAuth from "../components/auth/withAuth";

export class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            settings: {
                theme: 0
            }
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleTheme = this.handleTheme.bind(this);

        var ciSettings = JSON.parse(localStorage.getItem("ciSettings"));
        if (ciSettings) {
            this.state.settings.theme = ciSettings.theme;
        }
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    handleTheme = (event, index, filter) => {
        this.setState({ settings: { ...this.state.settings, theme: filter } }, () => {
            localStorage.setItem("ciSettings", JSON.stringify({ theme: filter }));
            this.refresh();
        });
    };

    refresh() {
        window.location.reload();
    }

    componentDidMount() {
        this.setState({ hasAction: false, isLoading: false });
    }

    render() {
        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Settings"}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div style={{ paddingLeft: 15 }}>
                        <SelectField name="theme" hintText="Select theme..." floatingLabelText="Theme" value={this.state.settings.theme} onChange={this.handleTheme}>
                            <MenuItem value={0} primaryText="Default" />
                            <MenuItem value={1} primaryText="Red" />
                            <MenuItem value={2} primaryText="Purple" />
                            <MenuItem value={3} primaryText="Green" />
                            <MenuItem value={4} primaryText="Pink" />
                            <MenuItem value={5} primaryText="Blue" />
                            <MenuItem value={6} primaryText="Indigo" />
                            <MenuItem value={7} primaryText="Light Pink" />
                            <MenuItem value={8} primaryText="Cyan" />
                        </SelectField>
                    </div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(Settings);
