import React from "react";

import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";
import CircularProgress from "material-ui/CircularProgress";

import fetchClient from "../../components/utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

export class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            property: []
        };

        this.handleGoBack = this.handleGoBack.bind(this);
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    componentDidMount() {
        var self = this;

        fetchClient
            .get(`ci/info-${self.props.match.params.type ? "in" : "out"}/${self.props.match.params.id}`)
            .then(function (response) {
                self.setState({ property: response.data, hasAction: false, isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Info for " + this.state.property.propertyName + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div style={{ paddingLeft: 15, marginTop: 15 }}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.property.propertyInfo }} />
                    </div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(Info);
