import React from "react";

import CircularProgress from "material-ui/CircularProgress";
import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";

import withAuth from "../components/auth/withAuth";

import marked from "marked";
export class WhatsNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            changelog: ""
        };

        this.handleGoBack = this.handleGoBack.bind(this);
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    componentDidMount() {
        const readmePath = "CHANGELOG.md";

        fetch(readmePath)
            .then(response => {
                return response.text();
            })
            .then(text => {
                this.setState({ changelog: marked(text) });
            });

        this.setState({ hasAction: false, isLoading: false });
    }

    render() {
        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "What's New"}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div style={{ paddingLeft: 15 }} dangerouslySetInnerHTML={{ __html: this.state.changelog }}></div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(WhatsNew);
