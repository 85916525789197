import React from "react";
import { Step, Stepper, StepLabel, StepContent } from "material-ui/Stepper";
import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";
import CircularProgress from "material-ui/CircularProgress";

import { load as loadCheckOut } from "../../components/reducers/checkout";

import fetchClient from "../../components/utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

import CheckOutForm from "../../components/checkout/CheckOutForm";
import InventoryForm from "../../components/checkout/InventoryForm";
import OtherForm from "../../components/checkout/OtherForm";

import { connect } from "react-redux";

import { reduxForm, arrayPush, change } from "redux-form";

class CheckOut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            booking: [],
            page: 0
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    handleSubmit(values) {
        var self = this;

        fetchClient
            .post("ci/checkout/" + self.state.booking.refId, values)
            .then(function (response) {
                self.props.history.push("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleNext(values) {
        const { page } = this.state;
        if (page < 2) {
            this.setState({ page: page + 1 });
        }

        this.setState({ hasAction: true, currentAction: "Saving changes..." });
        this.updateValues(values);
    }

    handlePrev() {
        const { page } = this.state;
        if (page > 0) {
            this.setState({ page: page - 1 });
        }
    }

    updateValues(values) {
        var self = this;

        fetchClient
            .post("ci/checkout/" + self.state.booking.refId, values)
            .then(function (response) {
                self.setState({ hasAction: false, currentAction: "" });
                fetchClient
                    .get("ci/checkout/" + self.state.booking.refId)
                    .then(function (response) {})
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGoBack = () => this.props.history.push("/");

    componentWillMount() {
        var self = this;

        fetchClient
            .get("ci/checkout/" + self.props.match.params.id)
            .then(function (response) {
                self.setState({ booking: response.data }, () => {
                    self.props.load(response.data);
                    self.setState({ hasAction: false, isLoading: false });
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { page } = this.state;

        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Checkout for " + this.state.booking.name + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div>
                        {this.state.booking.needsInventoryCheck === 1 ? (
                            <Stepper activeStep={page} linear={false} orientation="vertical">
                                <Step>
                                    <StepLabel>Checkout</StepLabel>
                                    <StepContent>{page === 0 && <CheckOutForm onSubmit={values => this.handleNext(values)} />}</StepContent>
                                </Step>

                                <Step>
                                    <StepLabel>Inventory</StepLabel>
                                    <StepContent>{page === 1 && <InventoryForm previousPage={values => this.handlePrev(values)} onSubmit={values => this.handleNext(values)} />}</StepContent>
                                </Step>

                                <Step>
                                    <StepLabel>Other</StepLabel>
                                    <StepContent>{page === 2 && <OtherForm previousPage={values => this.handlePrev(values)} onSubmit={values => this.handleSubmit(values)} />}</StepContent>
                                </Step>
                            </Stepper>
                        ) : (
                            <Stepper activeStep={page} linear={false} orientation="vertical">
                                <Step>
                                    <StepLabel>Checkout</StepLabel>
                                    <StepContent>{page === 0 && <CheckOutForm onSubmit={values => this.handleNext(values)} />}</StepContent>
                                </Step>

                                <Step>
                                    <StepLabel>Other</StepLabel>
                                    <StepContent>{page === 1 && <OtherForm previousPage={values => this.handlePrev(values)} onSubmit={values => this.handleSubmit(values)} />}</StepContent>
                                </Step>
                            </Stepper>
                        )}
                    </div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

CheckOut = reduxForm({
    form: "checkoutform",
    destroyOnUnmount: false,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true
})(CheckOut);

CheckOut = connect(state => ({ initialValues: state.checkout.data }), { load: loadCheckOut, arrayPush, change })(CheckOut);

export default withAuth(CheckOut);
