import React, { Component } from "react";

import AppBar from "material-ui/AppBar";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import Snackbar from "material-ui/Snackbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import AuthService from "./auth/AuthService";

library.add(faCalendar);

class Login extends Component {
    constructor() {
        super();

        this.state = {
            snackbar: false,
            message: "",
            username: "",
            password: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.Auth = new AuthService();
    }
    componentWillMount() {
        if (this.Auth.loggedIn()) {
            this.props.history.replace("/");
        }
    }
    render() {
        return (
            <div>
                <AppBar
                    showMenuIconButton={false}
                    title={
                        <span>
                            <FontAwesomeIcon icon="calendar" /> checkinApp
                        </span>
                    }
                />
                <form style={{ paddingLeft: 25 }} onSubmit={event => this.handleFormSubmit(event)}>
                    <TextField hintText="Username" floatingLabelText="Username" name="username" onChange={this.handleChange} />
                    <br />
                    <TextField type="password" hintText="Password" floatingLabelText="Password" name="password" onChange={this.handleChange} />
                    <br />
                    <RaisedButton style={{ marginTop: 15 }} label="Login" primary={true} type="submit" />
                    <Snackbar open={this.state.snackbar} message={this.state.message} autoHideDuration={4000} onRequestClose={event => this.setState({ snackbar: false })} />
                </form>
            </div>
        );
    }

    handleFormSubmit(e) {
        e.preventDefault();

        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                this.props.history.replace("/");
            })
            .catch(err => {
                this.setState({ snackbar: true, message: err.message });
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
}

export default Login;
