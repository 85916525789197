import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.css";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import theme from "./theme";

import EventsList from "./components/EventsList";
import PreCheckin from "./components/precheckin/PreCheckin";
import CheckIn from "./components/checkin/CheckIn";
import CheckOut from "./components/checkout/CheckOut";
import Login from "./components/Login";
import Contract from "./components/contract/Contract";
import Settings from "./components/Settings";
import WhatsNew from "./components/WhatsNew";
import CheckInEdit from "./components/checkin/CheckInEdit";
import CheckOutEdit from "./components/checkout/CheckOutEdit";
import TaskEdit from "./components/task/TaskEdit";
import TaskAdd from "./components/task/TaskAdd";
import IssueAdd from "./components/issue/IssueAdd";
import Info from "./components/info/Info";
import Links from "./components/links/Links";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

var selectTheme = 0;
var ciSettings = JSON.parse(localStorage.getItem("ciSettings"));
if (ciSettings) {
    if (ciSettings.theme) {
        selectTheme = ciSettings.theme;
    }
}

const CIATheme = getMuiTheme(theme[selectTheme]);

class App extends Component {

    render() {
        return (
            <MuiThemeProvider muiTheme={CIATheme}>
                <React.Fragment>
                    <HashRouter>
                        <Switch>
                            <Route exact path="/" component={EventsList} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/settings" component={Settings} />
                            <Route exact path="/whats-new" component={WhatsNew} />
                            <Route exact path="/precheckin/:id" component={PreCheckin} />
                            <Route exact path="/checkin/:id" component={CheckIn} />
                            <Route exact path="/checkout/:id" component={CheckOut} />
                            <Route exact path="/contract/:id" component={Contract} />
                            <Route exact path="/checkin/edit/:id" component={CheckInEdit} />
                            <Route exact path="/checkout/edit/:id" component={CheckOutEdit} />
                            <Route exact path="/task/edit/:id" component={TaskEdit} />
                            <Route exact path="/task/add" component={TaskAdd} />
                            <Route exact path="/links/:id" component={Links} />
                            <Route exact path="/info/:type/:id" component={Info} />
                            <Route exact path="/issue/add/:id" component={IssueAdd} />
                            <Redirect to="/" />
                        </Switch>
                    </HashRouter>
                </React.Fragment>
            </MuiThemeProvider>
        );
    }
}

export default App;