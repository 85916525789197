import React from "react";
import { formValueSelector, reduxForm } from "redux-form";
import validate from "./validate";
import RaisedButton from "material-ui/RaisedButton";

import { connect } from "react-redux";

class InventoryForm extends React.Component {
    render() {
        const { handleSubmit, propertyId, invalid, previousPage } = this.props;

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <RaisedButton style={{ marginTop: 5, marginBottom: 10 }} label={"Open Inventory"} onClick={() => window.open("https://inventoryapp.stay-u-nique.com/#/find/" + propertyId)} />

                <div>
                    <div style={{ marginTop: 5 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />
                        <RaisedButton label="Next" disableTouchRipple={true} disableFocusRipple={true} primary={true} disabled={invalid} type="submit" style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

InventoryForm = reduxForm({
    form: "checkoutform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(InventoryForm);

const selector = formValueSelector("checkoutform");

InventoryForm = connect(state => {
    const { propertyId, bookingId } = selector(state, "propertyId", "bookingId");
    return {
        propertyId,
        bookingId
    };
})(InventoryForm);

export default InventoryForm;
