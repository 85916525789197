import React from "react";

import RaisedButton from "material-ui/RaisedButton";

import { Toggle } from "redux-form-material-ui";

import { reduxForm, Field } from "redux-form";
import validate from "./validate";

class StatusForm extends React.Component {
    render() {
        const { previousPage, handleSubmit, invalid, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <div>
                    <Field name="precheckinDone" type="checkbox" component={Toggle} label="Pre checkin Done" labelPosition="right" />
                </div>

                <div>
                    <div style={{ marginTop: 15 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />
                        <RaisedButton label="Close" disableTouchRipple={true} disableFocusRipple={true} primary={true} type="submit" disabled={submitting || invalid} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

StatusForm = reduxForm({
    form: "precheckinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(StatusForm);

export default StatusForm;
