import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import RaisedButton from "material-ui/RaisedButton";
import { Toggle } from "redux-form-material-ui";

class OtherForm extends React.Component {
    render() {
        const { handleSubmit, previousPage, submitting, invalid } = this.props;

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <div>
                    <Field name="checkoutDone" type="checkbox" component={Toggle} label="Check out Done" labelPosition="right" />
                </div>

                <div>
                    <div style={{ marginTop: 5 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />
                        <RaisedButton label="Close" disableTouchRipple={true} disableFocusRipple={true} primary={true} type="submit" disabled={submitting || invalid} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

OtherForm = reduxForm({
    form: "checkoutform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(OtherForm);

export default OtherForm;
