import React from "react";
import validate from "./validate";

import Subheader from "material-ui/Subheader";

import { connect } from "react-redux";

import { reduxForm, formValueSelector } from "redux-form";

import moment from "moment";

class BookingSummary extends React.Component {
    render() {
        const { numNights, departureDate, arrivalDate, channel, checkinTodo, taxAmount, longTerm, numAdults, guestNames, cleaningFee, balanceDue, totalPaid } = this.props;
        return (
            <div>
                <Subheader>Booking Info</Subheader>
                <div style={{ paddingLeft: 15 }}>
                    <b>Number of nights:</b> {numNights} <br />
                    <b>Check In</b>: {arrivalDate} <br />
                    <b>Check Out</b>: {departureDate} <br />
                    <b>Adults</b>: {numAdults} <br />
                    <b>Channel</b>: {channel} <br />
                    {cleaningFee > 0 ? (
                        <span>
                            <b>Cleaning Fee</b>: €{cleaningFee}
                        </span>
                    ) : null}
                    <br />
                    {!longTerm ? (
                        <div>
                            <b>Tax amount</b>: €{taxAmount} <br />
                        </div>
                    ) : null}
                    <div>
                        <b>Paid</b>: €{totalPaid} <br />
                        <b>Remaining</b>: €{balanceDue} <br />
                    </div>
                    <h3>Guests</h3>
                    <ul>{guestNames ? guestNames.map((name, index) => <li key={index}>{name}</li>) : null}</ul>
                    <h3>To Be Done</h3>
                    <ul>{checkinTodo ? checkinTodo.map(todo => <li key={todo}>{todo}</li>) : null}</ul>
                </div>
            </div>
        );
    }
}

BookingSummary = reduxForm({
    form: "checkinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(BookingSummary);

const selector = formValueSelector("checkinform");

BookingSummary = connect(state => {
    const { numNights, departureDate, channel, checkinTodo, ttVal, longTerm, numAdults, arrivalDate, guestNames, cleaningFee, balanceDue, totalPaid } = selector(
        state,
        "numNights",
        "departureDate",
        "channel",
        "checkinTodo",
        "ttVal",
        "longTerm",
        "numAdults",
        "arrivalDate",
        "guestNames",
        "cleaningFee",
        "balanceDue",
        "totalPaid"
    );

    const taxNights = numNights < 7 ? numNights : 7;

    return {
        numNights,
        channel,
        checkinTodo,
        ttVal,
        taxAmount: parseFloat(ttVal * numAdults * taxNights).toFixed(2),
        longTerm,
        arrivalDate: moment(arrivalDate).format("DD/MM/YYYY"),
        departureDate: moment(departureDate).format("DD/MM/YYYY"),
        numAdults,
        guestNames,
        cleaningFee,
        balanceDue,
        totalPaid
    };
})(BookingSummary);

export default BookingSummary;
