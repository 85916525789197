import React from "react";

import RaisedButton from "material-ui/RaisedButton";

import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";

import fetchClient from "../../components/utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

export class Links extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            booking: []
        };

        this.handleGoBack = this.handleGoBack.bind(this);
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    componentDidMount() {
        var self = this;

        fetchClient
            .get("ci/checkin/" + self.props.match.params.id)
            .then(function (response) {
                self.setState({ booking: response.data, hasAction: false, isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Links for " + this.state.booking.name + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {this.state.isLoading ? null : (
                    <div style={{ paddingLeft: 15, marginTop: 15 }}>
                        <RaisedButton
                            label="Chat"
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            onClick={() => window.open(`${process.env.REACT_APP_API_URL}/../Chat/index?id=${this.state.booking.bookingId}`)}
                        />

                        <br />
                        <br />

                        <RaisedButton
                            label="View in Admin"
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            onClick={() => window.open(`${process.env.REACT_APP_API_URL}/../Bookings/edit?id=${this.state.booking.bookingId}`)}
                        />

                        <br />
                        <br />

                        <RaisedButton label="View On Channel" disableTouchRipple={true} disableFocusRipple={true} onClick={() => window.open(this.state.booking.channelRef)} />

                        <br />
                        <br />

                        <RaisedButton
                            label="View On Wiki"
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            onClick={() => window.open("https://wiki-beta.stay-u-nique.com/results/" + this.state.booking.propertyId)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(Links);
