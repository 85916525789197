import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import validate from "./validate";
import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import { SelectField, Toggle, TextField } from "redux-form-material-ui";
import { connect } from "react-redux";

class OtherForm extends React.Component {
    hourOptions = () => {
        let hours = [];
        for (let i = 0; i <= 23; i++) {
            let val = ("0" + i).slice(-2);
            hours.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return hours;
    };

    minuteOptions = () => {
        let minutes = [];
        for (let i = 0; i <= 55; i += 5) {
            let val = ("0" + i).slice(-2);
            minutes.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return minutes;
    };

    render() {
        const { handleSubmit, previousPage, submitting, invalid, propertyOwnerId } = this.props;

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                {propertyOwnerId !== "1610" && propertyOwnerId !== "1606" && <p>Do not forget to take a selfie!</p>}

                <h3>Keys</h3>
                <div>
                    <Field floatingLabelText="Keys" floatingLabelFixed={true} hintText="Number of keys given" name="keysGiven" component={SelectField} label="Keys">
                        <MenuItem value={0} primaryText="0" />
                        <MenuItem value={1} primaryText="1" />
                        <MenuItem value={2} primaryText="2" />
                        <MenuItem value={3} primaryText="3" />
                        <MenuItem value={4} primaryText="4" />
                        <MenuItem value={5} primaryText="5" />
                        <MenuItem value={6} primaryText="6" />
                    </Field>
                </div>
                <h3>Checkout</h3>

                <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.3)" }}>Checkout Time</span>
                <div style={{ marginTop: 8 }}>
                    <Field name="departureHour" component="select">
                        {this.hourOptions()}
                    </Field>
                    :
                    <Field name="departureMinute" component="select">
                        {this.minuteOptions()}
                    </Field>
                </div>

                <div>
                    <Field
                        floatingLabelText="Checkout Notes"
                        floatingLabelFixed={true}
                        hintText="Guest requests for checkout"
                        name="checkoutNotes"
                        type="text"
                        component={TextField}
                        label="Checkout Notes"
                        multiLine={true}
                    />
                </div>

                <h3>Expectation</h3>
                <div>
                    <Field floatingLabelText="Expections" floatingLabelFixed={true} hintText="What was the guest expecting?" name="guestExpectation" component={SelectField} label="Expectation">
                        <MenuItem value={0} primaryText="None" />
                        <MenuItem value={1} primaryText="Friend/Family" />
                        <MenuItem value={2} primaryText="Hotel/Professional" />
                    </Field>
                </div>

                <h3>Checkin Status</h3>
                <div>
                    <Field name="checkinDone" type="checkbox" component={Toggle} label="Check in Done" labelPosition="right" />
                </div>

                <br />
                <div>
                    <div style={{ marginTop: 5 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />
                        <RaisedButton label="Close" disableTouchRipple={true} disableFocusRipple={true} primary={true} type="submit" disabled={submitting || invalid} style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

OtherForm = reduxForm({
    form: "checkinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(OtherForm);

const selector = formValueSelector("checkinform");

OtherForm = connect(
    state => {
        const { numNights, propertyGroup, propertyOwnerId } = selector(state, "numNights", "agentGroup", "propertyGroup", "propertyOwnerId");

        return {
            numNights,
            propertyGroup,
            propertyOwnerId
        };
    },
    { change }
)(OtherForm);

export default OtherForm;
