import axios from "axios";
import { createHashHistory } from "history";
import { version } from "../../../package.json";

export const history = createHashHistory();

const fetchClient = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_URL,
        method: "get",
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("token");
        config.headers = {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : "",
            "X-CheckinApp-Version": version
        };

        return config;
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            const { status } = error.response;
            if (status === 401) {
                localStorage.removeItem("token");
                history.replace("/login");
            }
        }
    );

    return instance;
};

export default fetchClient();
