import React from "react";

import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";

import fetchClient from "../../components/utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

export class IssueAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            issue: {
                refId: this.props.match.params.id,
                issuePriority: 1,
                issueDesc: ""
            }
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addIssue = this.addIssue.bind(this);
        this.handleSelectPriorityChange = this.handleSelectPriorityChange.bind(this);
    }

    addIssue() {
        var self = this;

        fetchClient
            .post("ci/issue", this.state.issue)
            .then(function (response) {
                self.props.history.push("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    handleChange = event => {
        this.setState({ issue: { ...this.state.issue, [event.target.name]: event.target.value } });
    };

    handleSelectPriorityChange = (event, index, values) => {
        this.setState({ issue: { ...this.state.issue, issuePriority: values } });
    };

    render() {
        return (
            <div>
                <AppBar
                    title="Add Issue"
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                <div style={{ paddingLeft: 15 }}>
                    <TextField
                        floatingLabelText="Issue Description"
                        floatingLabelFixed={true}
                        hintText="Description of the issue"
                        value={this.state.issue.issueDesc}
                        multiLine={true}
                        name="issueDesc"
                        onChange={this.handleChange}
                        rows={1}
                    />

                    <br />
                    <SelectField
                        name="issuePriority"
                        floatingLabelText="Type"
                        floatingLabelFixed={true}
                        hintText="Issue Priority"
                        value={this.state.issue.issuePriority}
                        onChange={this.handleSelectPriorityChange}
                    >
                        <MenuItem key={1} value={1} primaryText="Low" />
                        <MenuItem key={2} value={2} primaryText="Medium" />
                        <MenuItem key={3} value={3} primaryText="High" />
                    </SelectField>

                    <br />
                    <br />
                    <RaisedButton primary={true} onClick={this.addIssue} label="Add" />
                </div>
            </div>
        );
    }
}

export default withAuth(IssueAdd);
