import React from "react";

import RaisedButton from "material-ui/RaisedButton";
import AppBar from "material-ui/AppBar";
import CircularProgress from "material-ui/CircularProgress";
import IconButton from "material-ui/IconButton";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";

import fetchClient from "../../components/utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

import SignatureCanvas from "react-signature-canvas";

export class Contract extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            booking: [],
            disabled: false,
            message: ""
        };

        this.signContract = this.signContract.bind(this);
        this.handleResign = this.handleResign.bind(this);
    }

    handleGoBack = () => this.props.history.push("/");

    componentDidMount() {
        var self = this;

        fetchClient
            .get("ci/contract/" + self.props.match.params.id)
            .then(function (response) {
                self.setState({ booking: response.data, hasAction: false, isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleResign() {
        this.setState({ booking: { ...this.state.booking, hasSigned: false } });
    }

    signContract = () => {
        var self = this;

        if (!this.sigCanvas.isEmpty()) {
            const signature = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png");

            self.setState({ hasAction: true, currentAction: "Saving...", disabled: true });

            fetchClient
                .post("ci/contract/" + self.state.booking.refId, { signature: signature })
                .then(function (response) {
                    if (response.data.success) {
                        self.sigCanvas.clear();
                        fetchClient
                            .get("ci/contract/" + self.state.booking.refId)
                            .then(function (response) {
                                self.setState({ booking: response.data, hasAction: false });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        self.setState({ message: "Unable to save contract" });
                    }

                    self.setState({ disabled: false, hasAction: false });
                })
                .catch(function (error) {
                    self.setState({ message: "Unable to save contract", disabled: false, hasAction: false });
                    console.log(error);
                });
        }
    };

    render() {
        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Contract for " + this.state.booking.name + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />
                <div style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
                    {!this.state.isLoading ? (
                        <div>
                            <h3>Contract</h3>
                            <div dangerouslySetInnerHTML={{ __html: this.state.booking.contract }} />
                            <h3>Signature</h3>

                            {this.state.message ? <p style={{ color: "red" }}>{this.state.error}</p> : null}

                            {this.state.booking.hasSigned ? (
                                <div>
                                    <br />
                                    <RaisedButton label="View Signed Contract" disableTouchRipple={true} disableFocusRipple={true} onClick={() => window.open(this.state.booking.contractURL)} />

                                    <RaisedButton label="Re-sign" disableTouchRipple={true} disableFocusRipple={true} onClick={this.handleResign} style={{ marginLeft: 12 }} />

                                    <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} primary={true} onClick={this.handleGoBack} style={{ marginLeft: 12 }} />
                                </div>
                            ) : (
                                <div>
                                    <div className="signature_box">
                                        <SignatureCanvas
                                            penColor="grey"
                                            ref={ref => {
                                                this.sigCanvas = ref;
                                            }}
                                            canvasProps={{ width: 500, height: 400, className: "sigCanvas" }}
                                        />
                                    </div>
                                    <div>
                                        <RaisedButton label="Clear" disableTouchRipple={true} disableFocusRipple={true} onClick={() => this.sigCanvas.clear()} style={{ marginLeft: 12 }} />

                                        <RaisedButton
                                            label="Sign"
                                            disableTouchRipple={true}
                                            disableFocusRipple={true}
                                            disabled={this.state.disabled}
                                            primary={true}
                                            onClick={() => this.signContract()}
                                            style={{ marginLeft: 12 }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div style={{ paddingLeft: 10 }}>
                            <br />
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withAuth(Contract);
