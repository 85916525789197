const validate = values => {
    const errors = {};

    if (!values.emailAddress) {
        errors.emailAddress = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
        errors.emailAddress = "Invalid email address";
    }

    if (!values.payments || !values.payments.length) {
        errors.payments = {
            _error: "At least one payment must be entered"
        };
    } else {
        const paymentsArrayErrors = [];
        values.payments.forEach((payment, paymentIndex) => {
            const paymentErrors = {};
            if (!payment || !payment.paymentDate) {
                paymentErrors.paymentDate = "Required";
                paymentsArrayErrors[paymentIndex] = paymentErrors;
            }
            if (!payment || !payment.paymentDesc) {
                paymentErrors.paymentDesc = "Required";
                paymentsArrayErrors[paymentIndex] = paymentErrors;
            }

            if (!payment || payment.paymentAmount) {
                if (payment.paymentAmount.length === 0) {
                    paymentErrors.paymentAmount = "Required";
                    paymentsArrayErrors[paymentIndex] = paymentErrors;
                }
            }

            if (!payment || !payment.paymentType) {
                paymentErrors.paymentType = "Required";
                paymentsArrayErrors[paymentIndex] = paymentErrors;
            }

            return paymentErrors;
        });

        if (paymentsArrayErrors.length) {
            errors.payments = paymentsArrayErrors;
        }
    }

    if (!values.guests || !values.guests.length) {
        errors.guests = {
            _error: "At least one guest must be entered"
        };
    } else {
        const guestsArrayErrors = [];
        values.guests.forEach((guest, guestIndex) => {
            const guestErrors = {};
            if (!guest || !guest.firstName) {
                guestErrors.firstName = "Required";
                guestsArrayErrors[guestIndex] = guestErrors;
            }
            if (!guest || !guest.lastName) {
                guestErrors.lastName = "Required";
                guestsArrayErrors[guestIndex] = guestErrors;
            }

            if (!guest || !guest.passport) {
                guestErrors.passport = "Required";
                guestsArrayErrors[guestIndex] = guestErrors;
            }

            if (!guest || !guest.birthday) {
                guestErrors.birthday = "Required";
                guestsArrayErrors[guestIndex] = guestErrors;
            }

            if (!guest || !guest.countryCode) {
                guestErrors.countryCode = "Required";
                guestsArrayErrors[guestIndex] = guestErrors;
            }

            return guestErrors;
        });

        if (guestsArrayErrors.length) {
            errors.guests = guestsArrayErrors;
        }
    }
    return errors;
};

export default validate;
