import React from "react";
import { Step, Stepper, StepLabel, StepContent } from "material-ui/Stepper";
import AppBar from "material-ui/AppBar";
import Drawer from "material-ui/Drawer";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import InfoButton from "material-ui/svg-icons/action/info";
import IconButton from "material-ui/IconButton";
import CircularProgress from "material-ui/CircularProgress";

import AuthService from "../../components/auth/AuthService";
import withAuth from "../../components/auth/withAuth";

import PassportForm from "../../components/checkin/PassportForm";
import PaymentsForm from "../../components/checkin/PaymentsForm";
import BookingInfo from "../../components/checkin/BookingInfo";
import OtherForm from "../../components/checkin/OtherForm";
import BookingSummary from "../../components/checkin/BookingSummary";

import fetchClient from "../../components/utils/fetchClient";
import moment from "moment";

import { load as loadCheckin } from "../../components/reducers/checkin";

import { connect } from "react-redux";

import Pusher from "pusher-js";

import { reduxForm, arrayPush, change } from "redux-form";

const Auth = new AuthService();

class CheckIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            countriesList: [],
            isLoading: true,
            hasAction: true,
            currentAction: "Loading...",
            booking: [],
            page: 0,
            drawer_open: false,
            passportError: ""
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.fetchCheckin = this.fetchCheckin.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.fetchCountries = this.fetchCountries.bind(this);
    }

    handleDrawerToggle = () => this.setState({ drawer_open: !this.state.drawer_open });

    handleDrawerClose = () => this.setState({ drawer_open: false });

    handleGoBack() {
        this.props.history.push("/");
    }

    handleSubmit(values) {
        var self = this;

        fetchClient
            .post("ci/checkin/" + self.state.booking.refId, values)
            .then(function (response) {
                self.props.history.push("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleNext(values) {
        const { page } = this.state;
        if (page < 3) {
            this.setState({ page: page + 1 });
        }

        this.setState({ hasAction: true, currentAction: "Saving changes..." });
        this.updateValues(values);
    }

    handlePrev() {
        const { page } = this.state;
        if (page > 0) {
            this.setState({ page: page - 1 });
        }
    }

    updateValues(values) {
        var self = this;

        fetchClient
            .post("ci/checkin/" + self.state.booking.refId, values)
            .then(function (response) {
                self.setState({ hasAction: false, currentAction: "" });
                fetchClient
                    .get("ci/checkin/" + self.state.booking.refId)
                    .then(function (response) {
                        self.setState({ booking: response.data });
                        self.props.load(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGoBack = () => this.props.history.push("/");

    componentWillMount() {
        this.pusher = new Pusher("366a23eaf5813ab1661e", {
            cluster: "eu",
            encrypted: true
        });

        const decoded = Auth.getProfile();
        const agentId = decoded.AgentId;
        this.pusher.subscribe("ciclient-" + agentId);

        this.fetchCountries();
        this.fetchCheckin();
    }

    componentDidMount() {
        var self = this;
        var socketId = null;

        self.pusher.connection.bind("connected", function () {
            socketId = self.pusher.connection.socket_id;
            localStorage.setItem("socketId", socketId);
        });

        self.pusher.bind("addPayment", function (data) {
            if (self.state.booking.bookingId === data.bookingId) {
                self.props.dispatch(
                    arrayPush("checkinform", "payments", {
                        paymentId: data.paymentId,
                        paymentDesc: data.paymentDesc,
                        paymentAmount: data.paymentAmount,
                        paymentType: 8,
                        paymentDate: moment().format("YYYY-MM-DD")
                    })
                );
            }
        });

        self.pusher.bind("addGuest", function (data) {
            if (self.state.booking.bookingId === data.bookingId) {
                self.setState({ page: 1 });
                self.fetchCheckin();
            }
        });
    }

    fetchCheckin() {
        var self = this;

        fetchClient
            .get("ci/checkin/" + self.props.match.params.id)
            .then(function (response) {
                self.setState({ booking: response.data }, () => {
                    self.props.load(response.data);
                    self.setState({ hasAction: false, isLoading: false });
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchCountries() {
        var self = this;

        fetchClient
            .get("ci/countries")
            .then(function (response) {
                self.setState({ countriesList: response.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentWillUnmount() {
        this.pusher.disconnect();
    }

    render() {
        const { page } = this.state;

        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Checkin for " + this.state.booking.name + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                    iconElementRight={
                        <IconButton>
                            <InfoButton color="white" onClick={this.handleDrawerToggle} />
                        </IconButton>
                    }
                />

                <Drawer openSecondary={true} docked={false} width={200} open={this.state.drawer_open} onRequestChange={drawer_open => this.setState({ drawer_open })}>
                    <BookingSummary />
                </Drawer>

                {!this.state.isLoading ? (
                    <Stepper activeStep={page} linear={false} orientation="vertical">
                        <Step>
                            <StepLabel>Booking Information</StepLabel>
                            <StepContent>{page === 0 && <BookingInfo onSubmit={values => this.handleNext(values)} />}</StepContent>
                        </Step>

                        <Step>
                            <StepLabel>Passport Info</StepLabel>
                            <StepContent>
                                <h3>Passport Information</h3>
                                {page === 1 && <PassportForm previousPage={values => this.handlePrev(values)} onSubmit={values => this.handleNext(values)} countries={this.state.countriesList} />}
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Payments</StepLabel>
                            <StepContent>{page === 2 && <PaymentsForm previousPage={values => this.handlePrev(values)} onSubmit={values => this.handleNext(values)} />}</StepContent>
                        </Step>

                        <Step>
                            <StepLabel>Other</StepLabel>
                            <StepContent>{page === 3 && <OtherForm previousPage={values => this.handlePrev(values)} onSubmit={values => this.handleSubmit(values)} />}</StepContent>
                        </Step>
                    </Stepper>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

CheckIn = reduxForm({
    form: "checkinform",
    destroyOnUnmount: false,
    enableReinitialize: true,
    forceUnregisterOnUnmount: true
})(CheckIn);

CheckIn = connect(state => ({ initialValues: state.checkin.data }), { load: loadCheckin, arrayPush, change })(CheckIn);

export default withAuth(CheckIn);
