import React from "react";

import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

import { Grid, Row, Col } from "react-flexbox-grid";

import fetchClient from "../../components/utils/fetchClient";

import { connect } from "react-redux";
import moment from "moment";

import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import validate from "./validate";
import { TextField } from "redux-form-material-ui";
import AuthService from "../../components/auth/AuthService";

const Auth = new AuthService();

const capitalizeFirst = value => value.charAt(0).toUpperCase() + value.slice(1);

const capitalize = value => value.toUpperCase();

const renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div>
        <div style={{ paddingBottom: 10, paddingTop: 15, fontSize: "12px", color: "rgba(0, 0, 0, 0.3)" }}>{label}</div>
        <div>
            <select {...input}>{children}</select>
            {touched && error && (
                <div>
                    <hr className="error-bar"></hr>
                    <div className="error">{error}</div>
                </div>
            )}
        </div>
    </div>
);

const renderGuests = ({ fields, meta: { error, submitFailed }, countries, countryCode }) => (
    <div>
        {fields.map((guest, index) => (
            <div key={index}>
                <h3>Guest #{index + 1}</h3>
                <RaisedButton label="Remove" disableTouchRipple={true} disableFocusRipple={true} onClick={() => fields.remove(index)} />
                <div>
                    <Grid fluid>
                        <Row>
                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="First Name"
                                    floatingLabelFixed={true}
                                    hintText="Guest first name"
                                    name={`${guest}.firstName`}
                                    normalize={capitalizeFirst}
                                    type="text"
                                    component={TextField}
                                    label="First Name"
                                />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="Last Name"
                                    floatingLabelFixed={true}
                                    hintText="Guest last name"
                                    name={`${guest}.lastName`}
                                    type="text"
                                    normalize={capitalizeFirst}
                                    component={TextField}
                                    label="Last Name"
                                />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="Passport"
                                    floatingLabelFixed={true}
                                    hintText="Guest passport"
                                    name={`${guest}.passport`}
                                    type="text"
                                    normalize={capitalize}
                                    component={TextField}
                                    label="Passport"
                                />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field floatingLabelText="Birthday" floatingLabelFixed={true} name={`${guest}.birthday`} type="date" component={TextField} />
                            </Col>
                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field floatingLabelText="Country" floatingLabelFixed={true} hintText="Guest country" name={`${guest}.countryCode`} component={renderSelectField} label="Country">
                                    {countries.map(country => {
                                        return (
                                            <option key={country.id} value={country.id}>
                                                {country.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        ))}

        {error && <span>{error}</span>}

        <div style={{ marginTop: 5 }}>
            <RaisedButton label="Add" disableTouchRipple={true} disableFocusRipple={true} onClick={() => fields.push({ birthday: moment().format("YYYY-MM-DD"), countryCode: countryCode })} />
        </div>
    </div>
);

class PassportForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            passport_open: false,
            agentId: 0,
            socketId: localStorage.getItem("socketId")
        };

        var decoded = Auth.getProfile();
        this.state.agentId = decoded.AgentId;

        this.passportLinkOpener = this.passportLinkOpener.bind(this);
        this.sendPassportLink = this.sendPassportLink.bind(this);
        this.handleClosePassport = this.handleClosePassport.bind(this);
    }

    passportLinkOpener() {
        this.setState({ passport_open: true });
    }

    sendPassportLink() {
        var self = this;

        self.setState({ passport_open: false });

        fetchClient.post("ci/passport/" + self.props.refId).catch(function (error) {
            console.log(error);
        });
    }

    handleClosePassport = () => {
        this.setState({ passport_open: false });
    };

    render() {
        const { handleSubmit, previousPage, pristine, reset, invalid, countries, countryCode, refId } = this.props;

        const passport_actions = [<FlatButton label="Cancel" primary={true} onClick={this.handleClosePassport} />, <FlatButton label="Send" primary={true} onClick={this.sendPassportLink} />];

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <FieldArray name="guests" component={renderGuests} countries={countries} countryCode={countryCode} />
                <div style={{ marginTop: 10 }}>
                    <RaisedButton style={{ marginRight: 10 }} label="Send Passport Link" disableTouchRipple={true} disableFocusRipple={true} onClick={this.passportLinkOpener} />

                    <RaisedButton style={{ marginTop: 10 }} label={"Scannerly"} href={"scannerly://open/" + refId + "?agentId=" + this.state.agentId + "&socketId=" + this.state.socketId + ""} />

                    <Dialog actions={passport_actions} modal={false} open={this.state.passport_open} onRequestClose={this.handleClose}>
                        Send passport link?
                    </Dialog>

                    <RaisedButton style={{ marginLeft: 10 }} label="Reset" primary={true} disabled={pristine} onClick={reset} disableTouchRipple={true} disableFocusRipple={true} />

                    <div style={{ marginTop: 10 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />

                        <RaisedButton label="Next" disableTouchRipple={true} disableFocusRipple={true} primary={true} disabled={invalid} type="submit" style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

PassportForm = reduxForm({
    form: "checkinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(PassportForm);

const selector = formValueSelector("checkinform");

PassportForm = connect(state => {
    const { refId, bookingId, countryCode } = selector(state, "refId", "bookingId", "countryCode");

    return {
        refId,
        bookingId,
        countryCode
    };
})(PassportForm);

export default PassportForm;
