import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import checkin from "../reducers/checkin";
import checkout from "../reducers/checkout";
import precheckin from "../reducers/precheckin";

const rootReducer = combineReducers({
    checkin,
    checkout,
    precheckin,
    form: formReducer
});

export default rootReducer;
