import React from "react";

import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import Toggle from "material-ui/Toggle";
import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import CircularProgress from "material-ui/CircularProgress";

import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";
import FullscreenDialog from "material-ui-fullscreen-dialog";

import fetchClient from "../../components/utils/fetchClient";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import withAuth from "../../components/auth/withAuth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faCheck, faTimes);

export class CheckinEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            agentsList: [],
            hasAction: true,
            currentAction: "Loading...",
            booking: {
                checkinAgents: []
            },
            currentImage: "",
            modal_open: false,
            selfie_open: false
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onToggleIsExactArrival = this.onToggleIsExactArrival.bind(this);
        this.onToggleIsAutoCheckin = this.onToggleIsAutoCheckin.bind(this);
        this.onToggleDone = this.onToggleDone.bind(this);
        this.sendSelfie = this.sendSelfie.bind(this);
        this.sendSelfieNoSocial = this.sendSelfieNoSocial.bind(this);
        this.handleCloseSelfie = this.handleCloseSelfie.bind(this);
        this.selfieOpener = this.selfieOpener.bind(this);
    }

    saveChanges() {
        var self = this;

        fetchClient
            .patch("ci/checkin/" + self.props.match.params.id, this.state.booking)
            .then(function (response) {
                self.setState({ hasAction: false, currentAction: "" });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    handleChange = event => {
        this.setState({ booking: { ...this.state.booking, checkinNotes: event.target.value }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    handleChangeArrivalExtra = event => {
        this.setState({ booking: { ...this.state.booking, arrivalByExtra: event.target.value }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    handleSelectChange = (event, index, values) => {
        this.setState({ booking: { ...this.state.booking, checkinAgents: values, isAutoCheckin: values.includes("213") ? 1 : 0 }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    handleSelectTypeChange = (event, index, value) => {
        this.setState({ booking: { ...this.state.booking, arrivalByType: value }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    handleDate = event => {
        this.setState({ booking: { ...this.state.booking, [event.target.name]: event.target.value }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    onToggleIsExactArrival(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;
        this.setState({ booking: { ...this.state.booking, isExactArrival: isToggled }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    }

    onToggleIsAutoCheckin(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;

        let agents = [];
        if (!isToggled) {
            agents = this.state.booking.checkinAgents.filter(item => item !== "213");
        } else {
            agents = [...this.state.booking.checkinAgents, "213"];
        }

        this.setState({ booking: { ...this.state.booking, isAutoCheckin: isToggled, checkinAgents: agents }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    }

    onToggleDone(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;
        this.setState({ booking: { ...this.state.booking, checkinDone: isToggled }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    }

    componentWillMount() {
        this.selfie = new Uppy({ id: "selfies", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "selfies",
                                upload_type: "selfie",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                fetchClient
                    .post("store", { id: self.state.booking.bookingId, type: "selfie", url: uploadURL })
                    .then(function (response) {
                        self.setState({ booking: { ...self.state.booking, selfie: uploadURL } }, () => {
                            self.saveChanges();
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
    }

    componentWillUnmount() {
        this.selfie.close();
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, modal_open: true });
        }
    }

    selfieOpener() {
        this.setState({ selfie_open: true });
    }
    handleCloseSelfie = () => {
        this.setState({ selfie_open: false });
    };

    sendSelfie() {
        var self = this;

        self.setState({ selfie_open: false, hasAction: true });

        fetchClient
            .post("ci/selfie/" + self.state.booking.refId, { hasSocialMediaPerm: 1 })
            .then(function (response) {
                self.setState({ booking: { ...self.state.booking, hasSentSelfie: 1 }, hasAction: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    sendSelfieNoSocial() {
        var self = this;

        self.setState({ selfie_open: false, hasAction: true });

        fetchClient
            .post("ci/selfie/" + self.state.booking.refId, { hasSocialMediaPerm: 0 })
            .then(function (response) {
                self.setState({ booking: { ...self.state.booking, hasSentSelfie: 1 }, hasAction: false });
            })
            .catch(function (error) {
                this.setState({ selfie_open: false });
            });
    }

    componentDidMount() {
        var self = this;

        fetchClient
            .get("ci/checkin/" + self.props.match.params.id)
            .then(function (response) {
                self.setState({ booking: response.data, hasAction: false, isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });

        fetchClient
            .get("agents")
            .then(function (response) {
                self.setState({ agentsList: response.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    menuItems(values) {
        return this.state.agentsList.map(agent => (
            <MenuItem key={agent.userName} insetChildren={true} checked={values && values.indexOf(agent.id) > -1} value={agent.agentId} primaryText={agent.userName} />
        ));
    }

    hourOptions = () => {
        let hours = [];
        for (let i = 0; i <= 23; i++) {
            let val = ("0" + i).slice(-2);
            hours.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return hours;
    };

    minuteOptions = () => {
        let minutes = [];
        for (let i = 0; i <= 55; i += 5) {
            let val = ("0" + i).slice(-2);
            minutes.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return minutes;
    };

    render() {
        const { checkinAgents } = this.state.booking;

        const selfie_actions = [
            <FlatButton label="Cancel" onClick={this.handleCloseSelfie} />,
            <FlatButton label="No" primary={true} onClick={this.sendSelfieNoSocial} />,
            <FlatButton label="Yes" primary={true} onClick={this.sendSelfie} />
        ];

        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Edit checkin for " + this.state.booking.name + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div style={{ paddingLeft: 15 }}>
                        <h3>Arrival Details</h3>

                        <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.3)" }}>Checkin Time</span>
                        <div style={{ marginTop: 8 }}>
                            <select name="arrivalHour" onChange={this.handleDate} value={this.state.booking.arrivalHour}>
                                {this.hourOptions()}
                            </select>
                            :
                            <select name="arrivalMinute" onChange={this.handleDate} value={this.state.booking.arrivalMinute}>
                                {this.minuteOptions()}
                            </select>
                        </div>

                        <br />
                        <Toggle toggled={this.state.booking.isExactArrival === 1 ? true : false} label={"Is Exact"} onToggle={this.onToggleIsExactArrival} labelPosition="right" />

                        <br />
                        <Toggle toggled={this.state.booking.isAutoCheckin === 1 ? true : false} label={"Auto checkin"} onToggle={this.onToggleIsAutoCheckin} labelPosition="right" />

                        <SelectField
                            name="arrivalByType"
                            hintText="Arrival by method"
                            floatingLabelFixed={true}
                            floatingLabelText="Arrival By"
                            value={this.state.booking.arrivalByType}
                            onChange={this.handleSelectTypeChange}
                        >
                            <MenuItem value="Plane, Barcelona Airport (BCN)" primaryText="Plane, Barcelona Airport (BCN)" />
                            <MenuItem value="Plane, Girona Airport (GRO)" primaryText="Plane, Girona Airport (GRO)" />
                            <MenuItem value="Plane, Reus Airport (REU)" primaryText="Plane, Reus Airport (REU)" />
                            <MenuItem value="Train" primaryText="Train" />
                            <MenuItem value="Cruise" primaryText="Cruise" />
                            <MenuItem value="Car" primaryText="Car" />
                            <MenuItem value="Other" primaryText="Other" />
                        </SelectField>

                        <br />

                        <TextField
                            name="arrivalByExtra"
                            floatingLabelText="Arrival Extra"
                            floatingLabelFixed={true}
                            hintText="Flight number etc"
                            value={this.state.booking.arrivalByExtra}
                            onChange={this.handleChangeArrivalExtra}
                        />
                        <br />

                        <SelectField
                            multiple={true}
                            name="checkinAgents"
                            hintText="Check in agents"
                            floatingLabelText="Agents"
                            floatingLabelFixed={true}
                            value={this.state.booking.checkinAgents}
                            onChange={this.handleSelectChange}
                        >
                            {this.menuItems(checkinAgents)}
                        </SelectField>

                        <br />

                        <TextField
                            hintText="Guest requests etc"
                            floatingLabelFixed={true}
                            floatingLabelText="Checkin Notes"
                            value={this.state.booking.checkinNotes}
                            multiLine={true}
                            onChange={this.handleChange}
                            rows={1}
                        />

                        <h3>Selfie</h3>
                        <DragDrop
                            locale={{
                                strings: {
                                    dropHereOr: "Upload image or %{browse}",
                                    browse: "browse"
                                }
                            }}
                            uppy={this.selfie}
                        />

                        <StatusBar uppy={this.selfie} hideUploadButton hideAfterFinish={false} showProgressDetails />

                        {this.state.booking.selfie ? (
                            <div>
                                <br />
                                <RaisedButton label="View Selfie" disableTouchRipple={true} disableFocusRipple={true} onClick={() => this.showModal(this.state.booking.selfie)} />

                                <RaisedButton style={{ marginLeft: 10 }} label="Send Selfie" disableTouchRipple={true} disableFocusRipple={true} onClick={this.selfieOpener} />

                                {this.state.booking.hasSentSelfie === 1 ? (
                                    <FontAwesomeIcon style={{ paddingLeft: 10, color: "green" }} icon="check" />
                                ) : (
                                    <FontAwesomeIcon style={{ paddingLeft: 10, color: "red" }} icon="times" />
                                )}
                            </div>
                        ) : null}

                        <FullscreenDialog
                            open={this.state.modal_open}
                            onRequestClose={(...args) => {
                                this.setState({ modal_open: false });
                            }}
                            title="View Image"
                        >
                            <div style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                                <img style={{ width: "100%", height: "auto" }} src={this.state.currentImage} alt="Selfie" />
                            </div>
                        </FullscreenDialog>

                        <Dialog actions={selfie_actions} modal={false} open={this.state.selfie_open} onRequestClose={this.handleClose}>
                            Do you have social media permission?
                        </Dialog>

                        <br />
                        <h3>Status</h3>

                        <Toggle toggled={this.state.booking.checkinDone === 1 ? true : false} label={"Checkin Done"} onToggle={this.onToggleDone} labelPosition="right" />
                    </div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(CheckinEdit);
