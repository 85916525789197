import React from "react";

import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import Toggle from "material-ui/Toggle";
import CircularProgress from "material-ui/CircularProgress";

import AppBar from "material-ui/AppBar";
import BackButton from "material-ui/svg-icons/navigation/arrow-back";
import IconButton from "material-ui/IconButton";

import fetchClient from "../../components/utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

export class CheckOutEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            agentsList: [],
            hasAction: true,
            currentAction: "Loading...",
            booking: {
                checkoutAgents: []
            }
        };

        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onToggleDone = this.onToggleDone.bind(this);
    }

    saveChanges() {
        var self = this;

        fetchClient
            .post("ci/checkout/" + self.state.booking.refId, self.state.booking)
            .then(function (response) {
                self.setState({ hasAction: false, currentAction: "" });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleGoBack() {
        this.props.history.push("/");
    }

    handleChange = event => {
        this.setState({ booking: { ...this.state.booking, checkoutNotes: event.target.value }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    handleSelectChange = (event, index, values) => {
        this.setState({ booking: { ...this.state.booking, checkoutAgents: values }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    handleDate = event => {
        this.setState({ booking: { ...this.state.booking, [event.target.name]: event.target.value }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    };

    onToggleDone(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;
        this.setState({ booking: { ...this.state.booking, checkoutDone: isToggled }, hasAction: true, currentAction: "Saving..." }, () => {
            this.saveChanges();
        });
    }

    componentDidMount() {
        var self = this;

        fetchClient
            .get("ci/checkout/" + self.props.match.params.id)
            .then(function (response) {
                self.setState({ booking: response.data, hasAction: false, isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });

        fetchClient
            .get("agents")
            .then(function (response) {
                self.setState({ agentsList: response.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    menuItems(values) {
        return this.state.agentsList.map(agent => (
            <MenuItem key={agent.userName} insetChildren={true} checked={values && values.indexOf(agent.id) > -1} value={agent.agentId} primaryText={agent.userName} />
        ));
    }

    hourOptions = () => {
        let hours = [];
        for (let i = 0; i <= 23; i++) {
            let val = ("0" + i).slice(-2);
            hours.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return hours;
    };

    minuteOptions = () => {
        let minutes = [];
        for (let i = 0; i <= 55; i += 5) {
            let val = ("0" + i).slice(-2);
            minutes.push(
                <option key={val} value={val}>
                    {val}
                </option>
            );
        }

        return minutes;
    };

    render() {
        const { checkoutAgents } = this.state.booking;

        return (
            <div>
                <AppBar
                    title={this.state.hasAction ? this.state.currentAction : "Edit checkout for " + this.state.booking.name + ""}
                    iconElementLeft={
                        <IconButton>
                            <BackButton onClick={this.handleGoBack} />
                        </IconButton>
                    }
                />

                {!this.state.isLoading ? (
                    <div style={{ paddingLeft: 15 }}>
                        <h3>Departure Details</h3>

                        <span style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.3)" }}>Checkout Time</span>
                        <div style={{ marginTop: 8 }}>
                            <select name="departureHour" onChange={this.handleDate} value={this.state.booking.departureHour}>
                                {this.hourOptions()}
                            </select>
                            :
                            <select name="departureMinute" onChange={this.handleDate} value={this.state.booking.departureMinute}>
                                {this.minuteOptions()}
                            </select>
                        </div>

                        <SelectField
                            multiple={true}
                            name="checkoutAgents"
                            hintText="Checkout agents"
                            floatingLabelText="Agents"
                            floatingLabelFixed={true}
                            value={this.state.booking.checkoutAgents}
                            onChange={this.handleSelectChange}
                        >
                            {this.menuItems(checkoutAgents)}
                        </SelectField>

                        <br />

                        <TextField
                            hintText="Guest checkout notes"
                            floatingLabelText="Checkout Notes"
                            floatingLabelFixed={true}
                            value={this.state.booking.checkoutNotes}
                            multiLine={true}
                            onChange={this.handleChange}
                            rows={1}
                        />
                        <br />
                        <h3>Status</h3>
                        <Toggle toggled={this.state.booking.checkoutDone === 1 ? true : false} label={"Checkout Done"} onToggle={this.onToggleDone} labelPosition="right" />
                    </div>
                ) : (
                    <div style={{ paddingLeft: 10 }}>
                        <br />
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    }
}

export default withAuth(CheckOutEdit);
