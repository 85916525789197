import React from "react";

import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import AttachIcon from "material-ui/svg-icons/editor/attach-file";
import CreditCard from "material-ui/svg-icons/action/credit-card";
import ClassicTextField from "material-ui/TextField";

import { formValueSelector, Field, reduxForm, FieldArray, arrayRemove, arrayPush, change } from "redux-form";
import validate from "./validate";
import { SelectField, TextField, Toggle } from "redux-form-material-ui";

import fetchClient from "../../components/utils/fetchClient";
import moment from "moment";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import FullscreenDialog from "material-ui-fullscreen-dialog";

import { Grid, Row, Col } from "react-flexbox-grid";

import { connect } from "react-redux";

class transactionRender extends React.Component {
    render() {
        return (
            <div>
                {this.props.input.value ? (
                    <IconButton>
                        <CreditCard onClick={() => window.open(this.props.input.value)} />
                    </IconButton>
                ) : null}
            </div>
        );
    }
}

class renderUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImage: "",
            modal_open: false
        };
    }

    componentWillMount() {
        this.recuploader = new Uppy({ id: "receipt", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "receipts",
                                upload_type: "receipt",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                self.props.change(`payments.${self.props.index}.receiptURL`, uploadURL);
            });
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, modal_open: true });
        }
    }

    componentWillUnmount() {
        this.recuploader.close();
    }

    render() {
        const { recuploader } = this;

        return (
            <>
                <div style={{ display: "inline-block", marginRight: 10 }}>
                    <br />
                    <DragDrop
                        locale={{
                            strings: {
                                dropHereOr: "Upload image or %{browse}",
                                browse: "browse"
                            }
                        }}
                        uppy={recuploader}
                    />

                    <StatusBar uppy={recuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />
                </div>

                <div style={{ display: "inline-block", marginRight: 10 }}>
                    {this.props.input.value ? (
                        <IconButton>
                            <AttachIcon onClick={() => this.showModal(this.props.input.value)} />
                        </IconButton>
                    ) : null}
                    <FullscreenDialog
                        open={this.state.modal_open}
                        onRequestClose={(...args) => {
                            this.setState({ modal_open: false });
                        }}
                        title="View Image"
                    >
                        <div style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                            <img style={{ width: "100%", height: "auto" }} src={this.state.currentImage} alt="View receipt" />
                        </div>
                    </FullscreenDialog>
                </div>
            </>
        );
    }
}

const renderPayments = ({ fields, meta: { error, submitFailed }, balanceDue, deleteOpener, bookingId, change }) => (
    <div>
        {fields.map((payments, index) => (
            <div key={index}>
                <h3>Payment #{index + 1}</h3>
                <RaisedButton label="Remove" disableTouchRipple={true} disableFocusRipple={true} disabled={fields.get(index)["paymentId"] ? true : false} onClick={() => deleteOpener(index)} />

                <div>
                    <Grid fluid>
                        <Row>
                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="Date"
                                    name={`${payments}.paymentDate`}
                                    floatingLabelFixed={true}
                                    type="date"
                                    label="Date"
                                    disabled={fields.get(index)["paymentId"] ? true : false}
                                    component={TextField}
                                />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="Description"
                                    floatingLabelFixed={true}
                                    hintText="Payment description"
                                    name={`${payments}.paymentDesc`}
                                    type="text"
                                    component={TextField}
                                    label="Description"
                                    disabled={fields.get(index)["paymentId"] ? true : false}
                                />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="Amount"
                                    floatingLabelFixed={true}
                                    hintText="Payment amount"
                                    name={`${payments}.paymentAmount`}
                                    type="number"
                                    step="0.01"
                                    component={TextField}
                                    label="Amount"
                                    disabled={fields.get(index)["paymentId"] ? true : false}
                                />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field
                                    floatingLabelText="Type"
                                    floatingLabelFixed={true}
                                    hintText="Payment type"
                                    name={`${payments}.paymentType`}
                                    component={SelectField}
                                    label="Type"
                                    disabled={fields.get(index)["paymentId"] ? true : false}
                                >
                                    <MenuItem key={1} value={1} primaryText={"Card"} />
                                    <MenuItem key={4} value={4} primaryText={"Bank Transfer"} />
                                    <MenuItem key={6} value={6} primaryText={"Cash"} />
                                    <MenuItem key={5} value={5} primaryText={"Other"} />
                                    <MenuItem key={10} value={10} primaryText={"Owner Transfer"} />
                                    {fields.get(index)["paymentId"] ? <MenuItem key={8} value={8} primaryText={"Payment Link"} /> : null}
                                </Field>
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                {fields.get(index)["transactionId"] ? (
                                    <div>
                                        <Field name={`${payments}.transactionURL`} component={transactionRender} />
                                    </div>
                                ) : (
                                    <div>
                                        <Field name={`${payments}.receiptURL`} index={index} change={change} component={renderUploader} />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <br />
            </div>
        ))}

        {error && <span className="error">{error}</span>}

        <div style={{ marginTop: 5 }}>
            <RaisedButton label="Add" disableTouchRipple={true} disableFocusRipple={true} onClick={() => fields.push({ paymentDate: moment().format("YYYY-MM-DD"), paymentAmount: balanceDue })} />
        </div>
    </div>
);

class PaymentsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            payment_open: false,
            link_open: false,
            index: null,
            isUploading: false,
            currentImage: "",
            modal_open: false,
            paymentAmount: 0,
            paymentDesc: "payment during checkin"
        };

        this.recalculateTax = this.recalculateTax.bind(this);
        this.deleteOpener = this.deleteOpener.bind(this);
        this.sendPaymentLink = this.sendPaymentLink.bind(this);
        this.paymentLinkOpener = this.paymentLinkOpener.bind(this);
        this.handleClosePayment = this.handleClosePayment.bind(this);
        this.handleCloseLink = this.handleCloseLink.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.calculateParking = this.calculateParking.bind(this);
    }

    componentWillMount() {
        this.depuploader = new Uppy({ id: "deposit", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "deposits",
                                upload_type: "deposit",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                fetchClient
                    .post("store", { id: self.props.bookingId, type: "deposit", url: uploadURL })
                    .then(function (response) {
                        self.props.change("deposit", uploadURL);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
    }

    deleteOpener(index) {
        this.setState({ payment_open: true, index: index });
    }

    handleDelete = () => {
        this.props.dispatch(arrayRemove("checkinform", "payments", this.state.index));
        this.setState({ payment_open: false });
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClosePayment = () => {
        this.setState({ payment_open: false });
    };

    handleCloseLink = () => {
        this.setState({ link_open: false });
    };

    paymentLinkOpener() {
        this.setState({ link_open: true });
    }

    componentWillUnmount() {
        this.depuploader.close();
    }

    sendPaymentLink() {
        var self = this;

        self.setState({ link_open: false });

        var linkDetails = {
            sockedId: localStorage.getItem("socketId"),
            paymentAmount: parseFloat(self.state.paymentAmount),
            paymentDesc: self.state.paymentDesc
        };

        fetchClient.post("ci/payment/" + self.props.refId, linkDetails).catch(function (error) {
            console.log(error);
        });
    }

    recalculateTax(event) {
        const ttVal = this.props.ttVal;

        this.props.change("overrideTTUnits", 1);
        this.props.change("taxAmount", Math.round(event.target.value * ttVal * 100) / 100);
    }

    calculateParking(event) {
        const otherExtras = this.props.otherExtras;
        const numNights = this.props.numNights;
        const hasParking = event.target.checked ? 1 : 0;
        var otherExtrasDesc = this.props.otherExtrasDesc;
        const depositAmount = this.props.depositAmount;
        const parkingDeposit = this.props.parkingDeposit;
        const parkingCost = this.props.parkingCost;

        if (hasParking) {
            if (parkingCost > 0) {
                this.props.change("otherExtras", parseFloat(otherExtras) + parkingCost * numNights);
                this.props.change("otherExtrasDesc", otherExtrasDesc + " Parking");
            }

            if (depositAmount === 0 && parkingDeposit > 0) {
                this.props.change("depositAmount", parkingDeposit);
            }
        } else {
            if (parkingCost > 0) {
                this.props.change("otherExtras", parseFloat(otherExtras) - parkingCost * numNights);
                this.props.change("otherExtrasDesc", otherExtrasDesc.replace(" Parking", ""));
            }

            if (depositAmount === parkingDeposit) {
                this.props.change("depositAmount", parseFloat(depositAmount - parkingDeposit));
            }
        }
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, modal_open: true });
        }
    }

    render() {
        const {
            handleSubmit,
            previousPage,
            invalid,
            totalToPay,
            totalPaid,
            balanceDue,
            totalPrice,
            taxAmount,
            deposit,
            bookingId,
            depositAmount,
            otherExtras,
            lateFee,
            channelId,
            ttVal,
            longTerm,
            needsCcConfimation,
            ccVerified,
            ccLastDigits,
            totalCharged,
            cleaningFee,
            hasParkingKey,
            parkingKeyGiven,
            parkingDeposit,
            parkingCost,
            numNights,
            noLateFee
        } = this.props;

        const payment_actions = [<FlatButton label="Cancel" primary={true} onClick={this.handleClosePayment} />, <FlatButton label="Delete" primary={true} onClick={this.handleDelete} />];

        const link_actions = [<FlatButton label="Cancel" onClick={this.handleCloseLink} />, <FlatButton label="Send" primary={true} onClick={this.sendPaymentLink} />];

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                {!longTerm ? (
                    <div>
                        <h3>City Tax (€{ttVal} per night)</h3>
                        <div>
                            <Field
                                floatingLabelText="Tax Units"
                                floatingLabelFixed={true}
                                hintText="City tax units"
                                name="taxUnits"
                                type="number"
                                component={TextField}
                                onChange={this.recalculateTax}
                                label="Tax Units"
                            />
                        </div>
                        <div>
                            <Field
                                floatingLabelText="Tax Amount"
                                floatingLabelFixed={true}
                                hintText="City tax Amount"
                                name="taxAmount"
                                type="number"
                                step="0.01"
                                component={TextField}
                                label="Tax Amount"
                            />
                        </div>
                    </div>
                ) : null}
                {noLateFee === 0 && (
                    <div>
                        <h3>Late Fee</h3>
                        <p className="error">€20 after 21:00, €50 after 00:00 (midnight)</p>
                        <div>
                            <Field floatingLabelText="Late Fee" floatingLabelFixed={true} hintText="Late fee type" name="lateFee" component={SelectField} label="Late Fee Type">
                                <MenuItem value={0} primaryText="None" />
                                <MenuItem value={20} primaryText="After 21:00" />
                                <MenuItem value={50} primaryText="After midnight" />
                            </Field>
                        </div>
                    </div>
                )}
                {hasParkingKey === 1 && (
                    <div>
                        <h3>Parking Key</h3>
                        <p>
                            The cost of the parking is €{parseFloat(parkingCost * numNights)} nights (€{parkingCost} x {numNights}) with a deposit of €{parkingDeposit}
                        </p>
                        <Field name="parkingKeyGiven" type="checkbox" component={Toggle} onChange={this.calculateParking} label="Key Given" labelPosition="right" />
                    </div>
                )}
                {(channelId !== 7 && channelId !== 9) || (parkingKeyGiven && parkingDeposit > 0) ? (
                    <div>
                        <h3>Deposit (€{depositAmount})</h3>
                        <div>
                            <Field floatingLabelText="Deposit Type" floatingLabelFixed={true} hintText="Type of deposit" name="depositType" component={SelectField} label="Deposit Type">
                                <MenuItem value={0} primaryText="None" />
                                <MenuItem value={1} primaryText="Preauth" />
                                <MenuItem value={2} primaryText="Charged" />
                            </Field>
                        </div>

                        <br />
                        <DragDrop
                            locale={{
                                strings: {
                                    dropHereOr: "Upload image or %{browse}",
                                    browse: "browse"
                                }
                            }}
                            uppy={this.depuploader}
                        />

                        <StatusBar uppy={this.depuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

                        {deposit ? (
                            <div>
                                <br />
                                <RaisedButton label="View Deposit" disableTouchRipple={true} disableFocusRipple={true} onClick={() => this.showModal(deposit)} />
                            </div>
                        ) : null}

                        <FullscreenDialog
                            open={this.state.modal_open}
                            onRequestClose={(...args) => {
                                this.setState({ modal_open: false });
                            }}
                            title="View Image"
                        >
                            <div style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                                <img style={{ width: "100%", height: "auto" }} src={this.state.currentImage} alt="View deposit" />
                            </div>
                        </FullscreenDialog>
                    </div>
                ) : null}
                <h3>Extras</h3>
                <div>
                    <Field
                        floatingLabelText="Other Extras Desc"
                        floatingLabelFixed={true}
                        hintText="Description for other extras"
                        name="otherExtrasDesc"
                        component={TextField}
                        label="Other Extras Desc"
                    />
                </div>
                <div>
                    <Field
                        floatingLabelText="Other Extras"
                        floatingLabelFixed={true}
                        hintText="Other extras amount"
                        type="number"
                        step="0.01"
                        name="otherExtras"
                        meta={{ initial: 0 }}
                        component={TextField}
                        label="Other Extras"
                    />
                </div>
                {needsCcConfimation && !ccVerified ? (
                    <div>
                        <br />
                        <h3>Credit Card Verification</h3>
                        <p>
                            Please ask guest to show the card {ccLastDigits} and verify with a passport/ID the ownership of the card. The card was charged €{totalCharged}
                        </p>
                        <p style={{ color: "red" }}>If you are not able to confirm the ownership of the above card, please charge a new card from guests</p>

                        <Field name="ccVerified" type="checkbox" component={Toggle} label="I checked the card" labelPosition="right" />
                    </div>
                ) : null}
                <h3>Payments</h3>
                <FieldArray name="payments" deleteOpener={this.deleteOpener} balanceDue={balanceDue} component={renderPayments} change={this.props.change} bookingId={bookingId} />
                <Dialog actions={payment_actions} modal={false} open={this.state.payment_open} onRequestClose={this.handleClosePayment}>
                    Delete payment?
                </Dialog>
                <Dialog actions={link_actions} modal={false} open={this.state.link_open} onRequestClose={this.handleCloseLink}>
                    Payment Link <br />
                    <ClassicTextField floatingLabelText="Amount" floatingLabelFixed={true} value={this.state.paymentAmount} onChange={this.handleChange} name="paymentAmount" type="number" />
                    <br />
                    <ClassicTextField floatingLabelText="Description" floatingLabelFixed={true} name="paymentDesc" value={this.state.paymentDesc} onChange={this.handleChange} />
                </Dialog>
                <div style={{ marginTop: 10 }}>
                    <RaisedButton label="Send Payment Link" disableTouchRipple={true} disableFocusRipple={true} onClick={this.paymentLinkOpener} />
                </div>
                <h3>Balances</h3>
                <b>Rent:</b> &euro;{totalPrice} <br />
                <b>Cleaning Fee:</b> &euro;{cleaningFee} <br />
                <b>City Tax:</b> &euro;{taxAmount} <br />
                {noLateFee === 0 && (
                    <span>
                        <b>Late Fee:</b> &euro;{lateFee} <br />
                    </span>
                )}
                <b>Extras:</b> &euro;{otherExtras} <br />
                <br />
                <b>Total To be Paid:</b> &euro;{totalToPay} <br />
                <b>Total Paid:</b> &euro;{totalPaid}
                <br />
                <b>Balance Due:</b> &euro;{balanceDue}
                <br />
                <br />
                <div>
                    <div style={{ marginTop: 10 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />
                        <RaisedButton label="Next" disableTouchRipple={true} disableFocusRipple={true} primary={true} disabled={invalid} type="submit" style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

PaymentsForm = reduxForm({
    form: "checkinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(PaymentsForm);

const selector = formValueSelector("checkinform");

PaymentsForm = connect(
    state => {
        const {
            refId,
            propertyId,
            taxAmount,
            lateFee,
            totalPrice,
            totalToPay,
            balanceDue,
            otherExtras,
            channelId,
            overrideTTUnits,
            bookingId,
            deposit,
            depositAmount,
            numNights,
            ttVal,
            longTerm,
            ccVerified,
            needsCcConfimation,
            ccLastDigits,
            totalCharged,
            cleaningFee,
            hasParkingKey,
            otherExtrasDesc,
            propertyGroup,
            parkingKeyGiven,
            parkingCost,
            parkingDeposit,
            noLateFee,
            payments
        } = selector(
            state,
            "refId",
            "totalToPay",
            "totalPaid",
            "balanceDue",
            "propertyId",
            "taxAmount",
            "lateFee",
            "totalPrice",
            "payments",
            "otherExtras",
            "channelId",
            "overrideTTUnits",
            "bookingId",
            "deposit",
            "depositAmount",
            "numNights",
            "ttVal",
            "longTerm",
            "ccVerified",
            "needsCcConfimation",
            "ccLastDigits",
            "totalCharged",
            "cleaningFee",
            "hasParkingKey",
            "otherExtrasDesc",
            "propertyGroup",
            "parkingKeyGiven",
            "parkingCost",
            "parkingDeposit",
            "noLateFee"
        );

        const taxTotal = longTerm ? 0 : taxAmount;

        const paid = payments.reduce((total, item) => total + parseFloat(item.paymentAmount), 0).toFixed(2);

        return {
            totalToPay,
            totalPaid: paid,
            balanceDue,
            propertyId: propertyId,
            taxAmount: taxTotal,
            lateFee: lateFee,
            totalPrice,
            cleaningFee,
            otherExtras: otherExtras,
            ttVal: ttVal,
            overrideTTUnits,
            bookingId,
            refId,
            deposit,
            depositAmount,
            numNights,
            channelId,
            longTerm,
            ccVerified,
            needsCcConfimation,
            ccLastDigits,
            totalCharged,
            hasParkingKey,
            otherExtrasDesc,
            propertyGroup,
            parkingKeyGiven,
            parkingCost,
            parkingDeposit,
            noLateFee
        };
    },
    { arrayPush, arrayRemove, change }
)(PaymentsForm);

export default PaymentsForm;
