import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import validate from "./validate";
import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import { TextField, SelectField, Toggle } from "redux-form-material-ui";

import { connect } from "react-redux";
import moment from "moment";

import { Grid, Row, Col } from "react-flexbox-grid";

class BookingInfo extends React.Component {
    render() {
        const { handleSubmit, invalid } = this.props;
        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <div>
                    <Field name="withGuests" type="checkbox" component={Toggle} label="With guests" labelPosition="right" />
                </div>
                <div>
                    <Field name="markLinkedAsDone" type="checkbox" component={Toggle} label="Mark linked tasks as done" labelPosition="right" />
                </div>
                <h3>Contact Information</h3>

                <div>
                    <Field floatingLabelText="Email" floatingLabelFixed={true} hintText="Guest email address" name="emailAddress" type="email" component={TextField} label="Email" />
                </div>
                <div>
                    <Field floatingLabelText="Phone" floatingLabelFixed={true} hintText="Guest phone number" name="phoneNumber" type="text" component={TextField} label="Phone" />
                </div>

                <h3>Guest Information</h3>

                <div>
                    <Grid fluid>
                        <Row>
                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field floatingLabelText="Adults" floatingLabelFixed={true} hintText="Number of adults" name="numAdults" type="number" component={TextField} label="Adults" />
                            </Col>
                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field floatingLabelText="Children" floatingLabelFixed={true} hintText="Number of children" name="numChildren" type="number" component={TextField} label="Children" />
                            </Col>

                            <Col sm={10} xs={12} md={6} lg={4}>
                                <Field floatingLabelText="Infants" floatingLabelFixed={true} hintText="Number of infants" name="numInfants" type="number" component={TextField} label="Infants" />
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <div>
                    <Field floatingLabelText="Language" floatingLabelFixed={true} hintText="Guest language" name="customerLanguage" component={SelectField} label="Language">
                        <MenuItem key={"EN"} value={"EN"} primaryText={"English"} />
                        <MenuItem key={"CA"} value={"CA"} primaryText={"Catalan"} />
                        <MenuItem key={"CN"} value={"CN"} primaryText={"Chinese"} />
                        <MenuItem key={"FR"} value={"FR"} primaryText={"French"} />
                        <MenuItem key={"NL"} value={"NL"} primaryText={"Dutch"} />
                        <MenuItem key={"DA"} value={"DA"} primaryText={"Danish"} />
                        <MenuItem key={"DE"} value={"DE"} primaryText={"German"} />
                        <MenuItem key={"IT"} value={"IT"} primaryText={"Italian"} />
                        <MenuItem key={"PT"} value={"PT"} primaryText={"Portuguese"} />
                        <MenuItem key={"NO"} value={"NO"} primaryText={"Norwegian"} />
                        <MenuItem key={"RU"} value={"RU"} primaryText={"Russian"} />
                        <MenuItem key={"ES"} value={"ES"} primaryText={"Spanish"} />
                        <MenuItem key={"SV"} value={"SV"} primaryText={"Swedish"} />
                    </Field>
                </div>
                <div>
                    <div style={{ marginTop: 5 }}>
                        <RaisedButton label="Next" disableTouchRipple={true} disableFocusRipple={true} primary={true} disabled={invalid} type="submit" style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

BookingInfo = reduxForm({
    form: "checkinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(BookingInfo);

const selector = formValueSelector("checkinform");

BookingInfo = connect(state => {
    const { numNights, departureDate, channel, checkinTodo, ttVal, longTerm, numAdults } = selector(state, "numNights", "departureDate", "channel", "checkinTodo", "ttVal", "longTerm", "numAdults");

    const taxNights = numNights < 7 ? numNights : 7;

    return {
        numNights,
        channel,
        checkinTodo,
        ttVal,
        taxAmount: parseFloat(ttVal * numAdults * taxNights).toFixed(2),
        longTerm,
        departureDate: moment(departureDate).format("DD/MM/YYYY")
    };
})(BookingInfo);

export default BookingInfo;
