import React from "react";

import RaisedButton from "material-ui/RaisedButton";

import { reduxForm, formValueSelector } from "redux-form";
import validate from "./validate";
import FullscreenDialog from "material-ui-fullscreen-dialog";

import fetchClient from "../../components/utils/fetchClient";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";

import { connect } from "react-redux";

class KeyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: "",
            modal_open: false
        };
    }

    componentWillMount() {
        this.keypic = new Uppy({ id: "key", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "keys",
                                upload_type: "key",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                fetchClient
                    .post("store", { id: self.props.bookingId, type: "key", url: uploadURL })
                    .then(function (response) {
                        self.props.change("keyPic", uploadURL);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
    }

    componentWillUnmount() {
        this.keypic.close();
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, modal_open: true });
        }
    }

    render() {
        const { handleSubmit, previousPage, invalid, keyPic } = this.props;

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <div>
                    <h3>Keys Picture</h3>

                    <DragDrop
                        locale={{
                            strings: {
                                dropHereOr: "Upload image or %{browse}",
                                browse: "browse"
                            }
                        }}
                        uppy={this.keypic}
                    />
                    <StatusBar uppy={this.keypic} hideUploadButton hideAfterFinish={false} showProgressDetails />

                    {keyPic ? (
                        <div>
                            <br />
                            <RaisedButton label="View Image" disableTouchRipple={true} disableFocusRipple={true} onClick={() => this.showModal(keyPic)} />
                        </div>
                    ) : null}
                </div>

                <FullscreenDialog
                    open={this.state.modal_open}
                    onRequestClose={(...args) => {
                        this.setState({ modal_open: false });
                    }}
                    title="View Image"
                >
                    <div style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                        <img style={{ width: "100%", height: "auto" }} src={this.state.currentImage} alt="Key" />
                    </div>
                </FullscreenDialog>

                <div>
                    <div style={{ marginTop: 15 }}>
                        <RaisedButton label="Back" disableTouchRipple={true} disableFocusRipple={true} onClick={previousPage} />
                        <RaisedButton label="Next" disableTouchRipple={true} disableFocusRipple={true} primary={true} disabled={invalid} style={{ marginLeft: 10 }} type="submit" />
                    </div>
                </div>
            </form>
        );
    }
}

KeyForm = reduxForm({
    form: "precheckinform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(KeyForm);

const selector = formValueSelector("precheckinform");

KeyForm = connect(state => {
    const { keyPic, bookingId } = selector(state, "keyPic", "bookingId");
    return {
        keyPic,
        bookingId
    };
})(KeyForm);

export default KeyForm;
