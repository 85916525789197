import React from "react";

import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import LinearProgress from "material-ui/LinearProgress";
import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
import DropDownMenu from "material-ui/DropDownMenu";
import Toggle from "material-ui/Toggle";
import AppBar from "material-ui/AppBar";
import Drawer from "material-ui/Drawer";
import Subheader from "material-ui/Subheader";
import IconButton from "material-ui/IconButton";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import Dialog from "material-ui/Dialog";
import DatePicker from "material-ui/DatePicker";
import CircularProgress from "material-ui/CircularProgress";
import { TextField } from "material-ui";
import queryString from "query-string";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import Today from "material-ui/svg-icons/action/restore";
import AddIcon from "material-ui/svg-icons/action/note-add";
import ClearIcon from "material-ui/svg-icons/content/clear";
import NotePadIcon from "material-ui/svg-icons/action/speaker-notes";
import EventType from "material-ui/svg-icons/action/label";
import NavigateNext from "material-ui/svg-icons/navigation/chevron-right";
import NavigatePrev from "material-ui/svg-icons/navigation/chevron-left";

import fetchClient from "../components/utils/fetchClient";

import TextFieldIcon from "material-ui-textfield-icon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import debounce from "debounce";

import AuthService from "../components/auth/AuthService";
import withAuth from "../components/auth/withAuth";

import { version } from "../../package.json";

const Auth = new AuthService();

export class HeaderTitle extends React.Component {
    render() {
        var title = this.props.title.length > 35 ? this.props.title.substr(0, 35) + "..." : this.props.title;
        return (
            <span className={this.props.class}>
                {this.props.time} <b>{title}</b> <i>{this.props.agents ? "- " + this.props.agents : null}</i>{" "}
            </span>
        );
    }
}

export class SubtitleIn extends React.Component {
    render() {
        let colors = {
            0: "red",
            1: "green",
            2: "orange"
        };

        return (
            <span>
                {this.props.property}{" "}
                {this.props.skipBasket === 1 ? (
                    <span className="strikethrough">
                        <FontAwesomeIcon style={{ color: "red" }} icon="shopping-basket" />
                    </span>
                ) : null}{" "}
                {this.props.precheckin === 1 ? <FontAwesomeIcon style={{ color: "green" }} icon="shopping-basket" /> : null} {this.props.atapt === 1 ? <FontAwesomeIcon icon="hourglass-half" /> : null}{" "}
                {this.props.isExactArrival === 1 ? <FontAwesomeIcon icon="star" /> : null} {this.props.autoCheckIn === 1 ? <FontAwesomeIcon icon="door-open" /> : null}{" "}
                {this.props.longTerm === 1 ? <FontAwesomeIcon icon="user-clock" /> : null} {this.props.hasSentSelfie === 1 ? <FontAwesomeIcon icon="image" /> : null}{" "}
                {this.props.hasTasks === 1 ? <FontAwesomeIcon style={{ color: "orange" }} icon="tasks" /> : null} <FontAwesomeIcon style={{ color: colors[this.props.cleaningStatus] }} icon="broom" />{" "}
                {this.props.hasTransfer === 1 ? <FontAwesomeIcon style={{ color: "#e8ae01" }} icon="taxi" /> : null}{" "}
                {this.props.channel === "Marriott Homes & Villas" ? <FontAwesomeIcon style={{ color: "red" }} icon="heart" /> : null}{" "}
                {this.props.pendingMaintenanceTasks > 0 ? (
                    <span style={{ color: "orange" }}>
                        <FontAwesomeIcon icon="tools" /> {this.props.pendingMaintenanceTasks}
                    </span>
                ) : null}{" "}
                {this.props.isWorstPerformer === 1 ? <FontAwesomeIcon style={{ color: "red" }} icon="sad-tear" /> : null}{" "}
                {this.props.hasKeyPic === 1 ? <FontAwesomeIcon style={{ color: "green" }} icon="key" /> : null}{" "}
                {this.props.sofaBedsPrep > 0 ? <FontAwesomeIcon style={{ color: this.props.sofaBedsPrep === 1 ? "red" : "green" }} icon="couch" /> : null}{" "}
                {this.props.babyCotsPrep > 0 ? <FontAwesomeIcon style={{ color: this.props.babyCotsPrep === 1 ? "red" : "green" }} icon="baby" /> : null}{" "}
                {this.props.instructionsSent === 1 ? <FontAwesomeIcon style={{ color: "green" }} icon="paper-plane" /> : null}{" "}
            </span>
        );
    }
}

export class SubtitleOut extends React.Component {
    render() {
        return (
            <span>
                {this.props.property} {this.props.needsInventoryCheck === 1 ? <FontAwesomeIcon style={{ color: "red" }} icon="clipboard-list" /> : null}{" "}
                {this.props.needsMailPickup === 1 ? <FontAwesomeIcon style={{ color: "red" }} icon="envelope-square" /> : null}{" "}
                {this.props.longTerm === 1 ? <FontAwesomeIcon icon="user-clock" /> : null} {this.props.hasTasks === 1 ? <FontAwesomeIcon style={{ color: "orange" }} icon="tasks" /> : null}{" "}
                {this.props.pendingMaintenanceTasks > 0 ? (
                    <span style={{ color: "orange" }}>
                        <FontAwesomeIcon icon="tools" /> {this.props.pendingMaintenanceTasks}
                    </span>
                ) : null}{" "}
            </span>
        );
    }
}

export class SubtitleTask extends React.Component {
    render() {
        return (
            <span>
                {this.props.property} {this.props.isExact === 1 ? <FontAwesomeIcon icon="star" /> : null}
            </span>
        );
    }
}

export class SubtitleIssue extends React.Component {
    render() {
        return (
            <span>
                {this.props.property} {this.props.priority === 1 ? <FontAwesomeIcon icon="star" /> : null}
            </span>
        );
    }
}

export class EventsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            disabled: false,
            drawer_open: false,
            door_dialog_open: false,
            eventsList: [],
            accessLevel: 0,
            userId: 0,
            agentId: 0,
            profileId: 0,
            guardName: "",
            userName: "",
            agentsList: [],
            propertiesList: [],
            cleaningCompanyList: [],
            buildingsList: [],
            property: [],
            filters: {
                view_date: moment().format("YYYY-MM-DD"),
                view_type: 0,
                view_search: "",
                view_agent: 0,
                view_property: 0,
                view_hide_done: 0,
                view_cleaning_company: 0,
                view_building: 0
            },
            currentProperty: "",
            lockNotes: "",
            showBadge: false,
            submitEnabled: false,
            front_loading: false,
            apt_loading: false,
            front_lock: false,
            apt_lock: false,
            notePadOpen: false,
            notePadContent: ""
        };

        this.handDateChange = this.handDateChange.bind(this);
        this.handDatePrev = this.handDatePrev.bind(this);
        this.handDateNext = this.handDateNext.bind(this);
        this.handDateToday = this.handDateToday.bind(this);
        this.onToggleHideDone = this.onToggleHideDone.bind(this);
        this.loadeventsAll = this.loadeventsAll.bind(this);
        this.debouncedloadeventsAll = debounce(this.loadeventsAll, 1000);

        this.fetchAgents = this.fetchAgents.bind(this);
        this.fetchProperties = this.fetchProperties.bind(this);
        this.fetchCleaningCompanies = this.fetchCleaningCompanies.bind(this);
        this.fetchBuildings = this.fetchBuildings.bind(this);
        this.filterEvents = this.filterEvents.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.logout = this.logout.bind(this);
        this.markAtApartment = this.markAtApartment.bind(this);
        this.openDoorDialog = this.openDoorDialog.bind(this);
        this.handleCloseDoorDialog = this.handleCloseDoorDialog.bind(this);
        this.handleOpenDoorDialog = this.handleOpenDoorDialog.bind(this);
        this.onToggleConfirm = this.onToggleConfirm.bind(this);
        this.handleSubtitle = this.handleSubtitle.bind(this);
        this.saveNote = this.saveNote.bind(this);
        this.fetchNotePad = this.fetchNotePad.bind(this);
        this.handleChangeNotePad = this.handleChangeNotePad.bind(this);

        var ciViewConfig = JSON.parse(localStorage.getItem("ciViewConfig"));
        if (ciViewConfig) {
            this.state.filters = ciViewConfig;
        }

        var decoded = Auth.getProfile();
        this.state.profileId = decoded.profileId;
        this.state.accessLevel = decoded.accessLevel;
        this.state.userId = decoded.userId;

        //Override for industria
        if (this.state.userId === "2071") {
            this.state.accessLevel = 3;
        }

        this.state.userName = decoded.userName;

        if (decoded.AgentId) {
            this.state.agentId = decoded.AgentId;
        }
    }

    handleDrawerToggle = () => this.setState({ drawer_open: !this.state.drawer_open });

    handleDrawerClose = () => this.setState({ drawer_open: false });

    handleTypeFilter = (event, index, filter) => {
        this.setState({ filters: { ...this.state.filters, view_type: filter } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    };

    handleAgentFilter = (event, index, filter) => {
        this.setState({ filters: { ...this.state.filters, view_agent: filter } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    };

    handlePropertyFilter = (event, index, filter) => {
        this.setState({ filters: { ...this.state.filters, view_property: filter } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    };

    handleCleaningCompanyFilter = (event, index, filter) => {
        this.setState({ filters: { ...this.state.filters, view_cleaning_company: filter } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    };

    handleBuildingFilter = (event, index, filter) => {
        this.setState({ filters: { ...this.state.filters, view_building: filter } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    };

    clearFilter() {
        this.setState({ filters: { ...this.state.filters, view_search: "" } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    }

    filterEvents(event) {
        var search = event.target.value ? event.target.value.toString() : "";

        this.setState({ filters: { ...this.state.filters, view_search: search } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.debouncedloadeventsAll();
        });
    }

    handDateChange = (event, date) => {
        this.setState({ filters: { ...this.state.filters, view_date: moment(date).format("YYYY-MM-DD") } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
            this.fetchNotePad();
        });
    };

    handDateToday() {
        this.setState({ filters: { ...this.state.filters, view_date: moment().format("YYYY-MM-DD") } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
            this.fetchNotePad();
        });
    }

    handDatePrev() {
        var previous = moment(this.state.filters.view_date);
        previous.subtract(1, "days").format("YYYY-MM-DD");

        this.setState({ filters: { ...this.state.filters, view_date: previous.format("YYYY-MM-DD") } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
            this.fetchNotePad();
        });
    }

    handDateNext() {
        var next = moment(this.state.filters.view_date);
        next.add(1, "days").format("YYYY-MM-DD");

        this.setState({ filters: { ...this.state.filters, view_date: next.format("YYYY-MM-DD") } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
            this.fetchNotePad();
        });
    }

    onToggleHideDone(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;

        this.setState({ filters: { ...this.state.filters, view_hide_done: isToggled } }, () => {
            localStorage.setItem("ciViewConfig", JSON.stringify(this.state.filters));
            this.loadeventsAll();
        });
    }

    onToggleConfirm(event, isInputChecked) {
        var isToggled = isInputChecked ? 1 : 0;
        this.setState({ submitEnabled: isToggled });
    }

    toggleNotePad = () => {
        this.setState({ notePadOpen: !this.state.notePadOpen });
    };

    componentWillMount() {
        this.interval = setInterval(() => this.loadeventsAll(), 60000);

        this.loadeventsAll();
        this.fetchAgents();
        this.fetchProperties();
        this.fetchCleaningCompanies();
        this.fetchBuildings();
        this.fetchNotePad();
    }

    handleEventExpand = expanded => {
        this.setState({ expanded: expanded });
    };

    fetchNotePad() {
        var self = this;

        fetchClient
            .get(`ci/notes?${queryString.stringify({ view_date: self.state.filters.view_date })}`)
            .then(function (response) {
                self.setState({ notePadContent: response.data.note ? response.data.note : "" });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchAgents() {
        var self = this;

        fetchClient
            .get("agents")
            .then(function (response) {
                self.setState({ agentsList: response.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchCleaningCompanies() {
        var self = this;

        fetchClient
            .get("ca/companies")
            .then(function (response) {
                self.setState({ cleaningCompanyList: response.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchBuildings() {
        var self = this;

        fetchClient
            .get("buildings")
            .then(function (response) {
                self.setState({ buildingsList: response.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    fetchProperties() {
        var self = this;

        fetchClient
            .get("properties")
            .then(function (response) {
                self.setState({ propertiesList: response.data.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    markAtApartment(id) {
        var self = this;

        fetchClient
            .post("ci/status/" + id, { checkinStatus: "atapt" })
            .then(function (response) {
                self.loadeventsAll();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleChangeNotePad = event => {
        this.setState({ notePadContent: event.target.value }, () => {
            this.saveNote();
        });
    };

    saveNote() {
        var self = this;

        if (self.state.timeout) {
            clearTimeout(self.state.timeout);
        }

        self.state.timeout = setTimeout(() => {
            fetchClient.post("ci/notes", { view_date: self.state.filters.view_date, note: self.state.notePadContent }).catch(function (error) {
                console.log(error);
            });
        }, 1000);
    }

    loadeventsAll() {
        var self = this;

        if (
            self.state.filters.view_agent !== 0 ||
            self.state.filters.view_property !== 0 ||
            self.state.filters.view_type !== 0 ||
            self.state.filters.view_cleaning_company !== 0 ||
            self.state.filters.view_building !== 0
        ) {
            self.setState({ showBadge: true });
        } else {
            self.setState({ showBadge: false });
        }

        self.setState({ isLoading: true });
        fetchClient
            .get(`ci/events/${self.state.filters.view_type}?${queryString.stringify(self.state.filters)}`)
            .then(function (response) {
                self.setState({ eventsList: response.data.events, guardName: response.data.guardName, isLoading: false });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    logout() {
        this.handleDrawerClose();
        Auth.logout();
        this.props.history.replace("/login");
    }

    openDoorDialog(propertyId) {
        var self = this;

        fetchClient
            .get("ci/smartlock/" + propertyId)
            .then(function (response) {
                if (response.data.success) {
                    self.setState({
                        door_dialog_open: true,
                        currentProperty: propertyId,
                        lockNotes: response.data.lock.LockNotes,
                        front_lock: response.data.lock.hasSmartLock > 1 ? true : false,
                        apt_lock: response.data.lock.hasSmartLock < 3 ? true : false
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleCloseDoorDialog() {
        this.setState({ door_dialog_open: false, currentProperty: "", lockNotes: "", front_lock: false, apt_lock: false });
    }

    handleOpenDoorDialog(type) {
        var self = this;
        self.setState({ submitEnabled: false, front_loading: type === 2 ? true : false, apt_loading: type === 1 ? true : false });
        fetchClient
            .post("ci/smartlock/" + self.state.currentProperty, { doortype: type })
            .then(function (response) {
                if (response.data.success) {
                    self.setState({
                        front_loading: false,
                        apt_loading: false,
                        submitEnabled: true
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubtitle(event) {
        switch (event.eventType) {
            case 1:
                return (
                    <SubtitleIn
                        skipBasket={event.booking.skipBasket}
                        hasTasks={event.tasks.length && this.state.accessLevel > 2 ? 1 : 0}
                        longTerm={event.property.longTerm}
                        precheckin={event.booking.precheckinDone}
                        isExactArrival={event.booking.isExactArrival}
                        autoCheckIn={event.property.autoCheckIn}
                        atapt={event.booking.atApt}
                        property={event.property.propertyName}
                        hasSentSelfie={event.booking.hasSentSelfie}
                        cleaningStatus={event.booking.cleaningStatus}
                        hasTransfer={event.booking.hasTransfer}
                        channel={event.booking.channel}
                        pendingMaintenanceTasks={event.property.pendingMaintenanceTasks}
                        isWorstPerformer={event.property.isWorstPerformer}
                        hasKeyPic={event.booking.hasKeyPic}
                        sofaBedsPrep={event.booking.sofaBedsPrep}
                        babyCotsPrep={event.booking.babyCotsPrep}
                        instructionsSent={event.booking.instructionsSent}
                    />
                );
            case 2:
                return (
                    <SubtitleOut
                        hasTasks={event.tasks.length && this.state.accessLevel > 2 ? 1 : 0}
                        property={event.property.propertyName}
                        longTerm={event.property.longTerm}
                        needsInventoryCheck={event.property.needsInventoryCheck}
                        needsMailPickup={event.property.needsMailPickup}
                        pendingMaintenanceTasks={event.property.pendingMaintenanceTasks}
                    />
                );
            case 3:
                return <SubtitleTask property={event.property.propertyName} isExact={event.task.isExact} />;
            case 4:
                return <SubtitleIssue property={event.property.propertyName} priority={event.issue.issuePriority} />;
            default:
                return console.warn(`No case for event type "${event.eventType}"`);
        }
    }

    render() {
        const dialogButtons = [
            <RaisedButton
                label={this.state.front_loading ? <CircularProgress size={24} style={{ position: "absolute", top: "50%", left: "50%", marginTop: -12, marginLeft: -12 }} /> : "Front"}
                disabled={!this.state.submitEnabled || !this.state.front_lock}
                style={{ marginLeft: 15 }}
                primary={true}
                onClick={() => {
                    this.handleOpenDoorDialog(2);
                }}
            />,
            <RaisedButton
                label={this.state.apt_loading ? <CircularProgress size={24} style={{ position: "absolute", top: "50%", left: "50%", marginTop: -12, marginLeft: -12 }} /> : "Apt"}
                disabled={!this.state.submitEnabled || !this.state.apt_lock}
                style={{ marginRight: 15, marginLeft: 15 }}
                primary={true}
                onClick={() => {
                    this.handleOpenDoorDialog(1);
                }}
            />,
            <RaisedButton label="Close" onClick={this.handleCloseDoorDialog} />
        ];

        const rightButtons = (
            <div>
                <IconButton>
                    <Today color="white" onClick={this.handDateToday} />
                </IconButton>

                {this.state.accessLevel > 2 ? (
                    <IconButton>
                        <AddIcon
                            color="white"
                            onClick={() => {
                                this.props.history.push("/task/add");
                            }}
                        />
                    </IconButton>
                ) : null}

                <IconButton>
                    <SettingsIcon style={{ position: "relative" }} color="white" onClick={this.handleDrawerToggle} />
                </IconButton>
                {this.state.showBadge ? <div className="floatingBadge"></div> : null}
            </div>
        );

        return (
            <div>
                <div className="header">
                    <AppBar
                        title={
                            <span>
                                <FontAwesomeIcon icon="calendar" /> checkinApp
                            </span>
                        }
                        showMenuIconButton={false}
                        iconElementRight={rightButtons}
                    />

                    <Toolbar>
                        <ToolbarGroup firstChild={true}>
                            <IconButton>
                                <NotePadIcon color={this.state.notePadContent.length > 0 ? "orange" : null} onClick={this.toggleNotePad} />
                            </IconButton>
                            <IconButton>
                                <NavigatePrev onClick={this.handDatePrev} />
                            </IconButton>
                            <div style={{ width: 85 }}>
                                <DatePicker
                                    formatDate={date => moment(date).format("DD/MM/YYYY")}
                                    value={moment(this.state.filters.view_date).toDate()}
                                    autoOk={true}
                                    hintText="Date..."
                                    textFieldStyle={{ width: 80 }}
                                    onChange={this.handDateChange}
                                />
                            </div>
                            <IconButton>
                                <NavigateNext onClick={this.handDateNext} />
                            </IconButton>
                        </ToolbarGroup>
                        <ToolbarGroup>
                            {this.state.accessLevel > 2 ? (
                                <TextFieldIcon
                                    value={this.state.filters.view_search}
                                    name="search"
                                    style={{ maxWidth: 135 }}
                                    icon={<ClearIcon onClick={this.clearFilter} />}
                                    hintText={"Search..."}
                                    onChange={this.filterEvents}
                                />
                            ) : null}
                        </ToolbarGroup>
                    </Toolbar>

                    {this.state.accessLevel > 2 && this.state.notePadOpen && (
                        <div className="notes">
                            <TextField onChange={this.handleChangeNotePad} value={this.state.notePadContent} placeholder="Enter your notes..." multiLine={true} fullWidth={true} />
                        </div>
                    )}

                    <Drawer openSecondary={true} docked={false} width={200} open={this.state.drawer_open} onRequestChange={drawer_open => this.setState({ drawer_open })}>
                        <Subheader
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            checkinApp {version}
                        </Subheader>

                        {this.state.accessLevel > 2 ? (
                            <div>
                                <Subheader>
                                    <i>Guard: {this.state.guardName}</i>
                                </Subheader>

                                <MenuItem disabled={true}>Agent</MenuItem>
                                <DropDownMenu value={this.state.filters.view_agent} onChange={this.handleAgentFilter}>
                                    <MenuItem value={0} primaryText="All" />
                                    <MenuItem value={-1} primaryText="Unassigned Agents" />
                                    {this.state.agentsList.map(agent => {
                                        return <MenuItem key={agent.agentId} value={agent.agentId} primaryText={agent.userName} />;
                                    })}
                                </DropDownMenu>

                                <br />
                                <MenuItem disabled={true}>Property</MenuItem>
                                <DropDownMenu value={this.state.filters.view_property} onChange={this.handlePropertyFilter}>
                                    <MenuItem value={0} primaryText="All" />
                                    {this.state.propertiesList.map(property => {
                                        return <MenuItem key={property.propertyId} value={property.propertyId} primaryText={property.propertyName} />;
                                    })}
                                </DropDownMenu>

                                <br />
                                <MenuItem disabled={true}>Building</MenuItem>
                                <DropDownMenu value={this.state.filters.view_building} onChange={this.handleBuildingFilter}>
                                    <MenuItem value={0} primaryText="All" />
                                    {this.state.buildingsList.map(building => {
                                        return <MenuItem key={building.buildingId} value={building.buildingId} primaryText={building.buildingName} />;
                                    })}
                                </DropDownMenu>

                                <br />
                                <MenuItem disabled={true}>Cleaning Company</MenuItem>
                                <DropDownMenu value={this.state.filters.view_cleaning_company} onChange={this.handleCleaningCompanyFilter}>
                                    <MenuItem value={0} primaryText="All" />
                                    {this.state.cleaningCompanyList.map(company => {
                                        return <MenuItem key={company.companyId} value={company.companyId} primaryText={company.companyName} />;
                                    })}
                                </DropDownMenu>
                            </div>
                        ) : null}

                        <MenuItem disabled={true}>Type</MenuItem>
                        {this.state.accessLevel > 2 ? (
                            <DropDownMenu value={this.state.filters.view_type} onChange={this.handleTypeFilter}>
                                <MenuItem value={0} primaryText={"All"} />
                                <MenuItem value={1} primaryText={"Checkins"} />
                                <MenuItem value={2} primaryText={"Checkouts"} />
                                <MenuItem value={3} primaryText={"Tasks"} />
                            </DropDownMenu>
                        ) : (
                            <DropDownMenu value={this.state.filters.view_type} onChange={this.handleTypeFilter}>
                                <MenuItem value={0} primaryText={"All"} />
                                <MenuItem value={1} primaryText={"Checkins"} />
                                <MenuItem value={2} primaryText={"Checkouts"} />
                            </DropDownMenu>
                        )}
                        <br />
                        <br />
                        <Toggle
                            style={{ paddingLeft: 15 }}
                            toggled={this.state.filters.view_hide_done === 1 ? true : false}
                            label={"Hide Done"}
                            onToggle={this.onToggleHideDone}
                            labelPosition="right"
                        />

                        <div style={{ marginTop: 15, marginLeft: 15 }}>
                            <FontAwesomeIcon style={{ color: "green" }} icon="shopping-basket" /> <span style={{ marginLeft: 5 }}>Basket prepped</span> <br />
                            <span className="strikethrough">
                                <FontAwesomeIcon style={{ color: "red" }} icon="shopping-basket" />
                            </span>{" "}
                            <span style={{ marginLeft: 5 }}>No Basket</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="clipboard-list" /> <span style={{ marginLeft: 10 }}>Check Inventory</span> <br />
                            <FontAwesomeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} icon="hourglass-half" /> <span style={{ marginLeft: 10 }}>Checkin started</span> <br />
                            <FontAwesomeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} icon="door-open" /> <span style={{ marginLeft: 3 }}>Auto checkin</span> <br />
                            <FontAwesomeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} icon="star" /> <span style={{ marginLeft: 5 }}>Exact time</span> <br />
                            <FontAwesomeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} icon="user-clock" /> <span style={{ marginLeft: 4 }}>Long term</span> <br />
                            <FontAwesomeIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} icon="image" /> <span style={{ marginLeft: 7 }}>Selfie sent</span> <br />
                            {this.state.accessLevel > 2 && (
                                <span>
                                    <FontAwesomeIcon style={{ color: "orange" }} icon="tasks" /> <span style={{ marginLeft: 7 }}>Has Tasks</span>
                                    <br />
                                </span>
                            )}
                            <FontAwesomeIcon style={{ color: "red" }} icon="broom" /> <span style={{ marginLeft: 5 }}>Not cleaned</span> <br />
                            <FontAwesomeIcon style={{ color: "orange" }} icon="broom" /> <span style={{ marginLeft: 5 }}>Cleaning started</span> <br />
                            <FontAwesomeIcon style={{ color: "green" }} icon="broom" /> <span style={{ marginLeft: 5 }}>Cleaning done</span> <br />
                            <FontAwesomeIcon style={{ color: "#e8ae01" }} icon="taxi" /> <span style={{ marginLeft: 5 }}>Has Transfer</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="heart" /> <span style={{ marginLeft: 5 }}>Special Care Flat</span> <br />
                            <FontAwesomeIcon style={{ color: "orange" }} icon="tools" /> <span style={{ marginLeft: 5 }}>Maintenance Tasks</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="sad-tear" /> <span style={{ marginLeft: 5 }}>Low Performing</span> <br />
                            <FontAwesomeIcon style={{ color: "green" }} icon="couch" /> <span style={{ marginLeft: 5 }}>Sofa bed prepared</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="couch" /> <span style={{ marginLeft: 5 }}>Sofa bed not prepared</span> <br />
                            <FontAwesomeIcon style={{ color: "green" }} icon="baby" /> <span style={{ marginLeft: 5 }}>Cot prepared</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="baby" /> <span style={{ marginLeft: 5 }}>Cot not prepared</span> <br />
                            <FontAwesomeIcon style={{ color: "red" }} icon="envelope-square" /> <span style={{ marginLeft: 5 }}>Collect mail</span> <br />
                            <FontAwesomeIcon style={{ color: "green" }} icon="paper-plane" /> <span style={{ marginLeft: 5 }}>Instructions sent</span> <br />
                        </div>

                        {this.state.accessLevel > 2 && (
                            <RaisedButton
                                style={{ marginTop: 15, marginLeft: 15 }}
                                label={"My Map"}
                                onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}/../Maps/index?showCheckins=1&showCheckouts=1&showTasks=1&date=${this.state.filters.view_date}&agentId=${this.state.agentId}`
                                    )
                                }
                            />
                        )}

                        <RaisedButton
                            style={{ marginTop: 15, marginLeft: 15 }}
                            label={"What's New"}
                            onClick={() => {
                                this.props.history.push("/whats-new");
                            }}
                        />
                        <RaisedButton
                            style={{ marginTop: 15, marginLeft: 15 }}
                            label={"Settings"}
                            onClick={() => {
                                this.props.history.push("/settings");
                            }}
                        />
                        <RaisedButton style={{ marginTop: 15, marginLeft: 15, marginBottom: 15 }} disabled={this.state.disabled} label={"Logout " + this.state.userName} onClick={this.logout} />
                        <br />
                    </Drawer>
                </div>
                <div className="content">
                    {this.state.isLoading ? <LinearProgress mode="indeterminate" /> : null}

                    {this.state.eventsList.map(event => {
                        if (event.eventType === 1) {
                            event.colorType = "green";
                        } else if (event.eventType === 2) {
                            event.colorType = "red";
                        } else if (event.eventType === 3) {
                            event.colorType = "orange";
                            if (event.task.taskType === 6) {
                                event.colorType = "DodgerBlue";
                            }
                        } else {
                            event.colorType = "black";
                        }

                        if (event.eventType === 3 && event.task.taskType === 3) {
                            event.colorType = "purple";
                        }

                        if (event.eventType === 3 && event.task.taskType === 4) {
                            event.colorType = "hotpink";
                        }

                        if (event.eventType === 3 && event.task.taskType === 5) {
                            event.colorType = "darkgrey";
                        }

                        return (
                            <Card key={event.eventId} expanded={event.expanded} onExpandChange={this.handleEventExpand}>
                                <CardHeader
                                    className="card-header"
                                    title={
                                        <HeaderTitle
                                            class={event.eventStatus === 1 ? "strikeout" : null}
                                            time={moment(event.eventTime).format("HH:mm")}
                                            agents={event.agents}
                                            title={event.title.substr(0, 100)}
                                        />
                                    }
                                    subtitle={this.handleSubtitle(event)}
                                    actAsExpander={true}
                                    showExpandableButton={true}
                                    avatar={<EventType color={event.colorType} />}
                                    titleColor={event.type_color}
                                />
                                <CardText expandable={true}>
                                    {this.state.accessLevel > 2 && event.eventType < 3 && event.tasks.length ? (
                                        <div>
                                            <h4>Linked Tasks</h4>
                                            {event.tasks.map(task => {
                                                return (
                                                    <div key={task.taskId} style={{ paddingBottom: 10 }}>
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                            className={task.taskDone === 1 ? "strikeout" : ""}
                                                            onClick={() => {
                                                                this.props.history.push("/task/edit/" + task.taskId + "");
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="tasks" /> {task.taskDesc} {task.createdBy && task.createdBy.length > 0 ? <i>- created by {task.createdBy}</i> : null}{" "}
                                                            <FontAwesomeIcon icon="edit" />
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}

                                    <p>
                                        <FontAwesomeIcon icon="clock" /> {moment(event.eventTime).format("DD/MM/YYYY HH:mm")}
                                    </p>

                                    {this.state.accessLevel > 0 && this.state.accessLevel < 3 && (
                                        <div>
                                            {event.eventType === 1 && event.booking.arrivalByType ? (
                                                <p>
                                                    <FontAwesomeIcon icon="hand-point-right" /> {event.booking.arrivalByType}{" "}
                                                    {event.booking.arrivalByExtra && event.booking.arrivalByType === "Plane, Barcelona Airport (BCN)" ? (
                                                        <a target="_blank" rel="noopener noreferrer" href={event.booking.arrivalByExtraURL}>
                                                            ({event.booking.arrivalByExtra})
                                                        </a>
                                                    ) : null}{" "}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="globe" /> {event.booking.channel}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="users" /> {event.booking.guests}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="map" />{" "}
                                                    <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(event.property.address) + ""}>
                                                        {event.property.address}
                                                    </a>
                                                    {event.property.floor ? ", " + event.property.floor : null}
                                                    {event.property.door ? ", " + event.property.door : null} {event.property.buzzerInfo ? "(" + event.property.buzzerInfo + ")" : null}{" "}
                                                    {event.property.meetingPoint ? (
                                                        <span>
                                                            (
                                                            <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(event.property.meetingPoint) + ""}>
                                                                {event.property.meetingPoint}
                                                            </a>
                                                            )
                                                        </span>
                                                    ) : null}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 && event.booking.beds ? (
                                                <p>
                                                    <FontAwesomeIcon icon="bed" /> {event.booking.beds}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 && event.booking.notes ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sticky-note" /> {event.booking.notes} 
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 && event.property.propertyInfo ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sticky-note" />  {event.property.propertyInfo}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 && event.booking.departureDate ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sign-out-alt" /> {moment(event.booking.departureDate).format("DD/MM/YYYY HH:mm")}
                                                </p>
                                            ) : null}
                                            {event.eventType === 2 && event.property.nexteventTime ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sign-in-alt" />{" "}
                                                    <span className={moment(event.property.nexteventTime).isBefore(moment({ hour: 15, minute: 0 })) ? "error" : ""}>
                                                        {moment(event.property.nexteventTime).format("DD/MM/YYYY HH:mm")} ({event.property.nextisAutoCheckin ? "Auto " : null}Checkin)
                                                    </span>
                                                </p>
                                            ) : null}
                                        </div>
                                    )}

                                    {this.state.accessLevel > 2 && (
                                        <div>
                                            {event.eventType === 1 && event.booking.arrivalByType ? (
                                                <p>
                                                    <FontAwesomeIcon icon="hand-point-right" /> {event.booking.arrivalByType}{" "}
                                                    {event.booking.arrivalByExtra && event.booking.arrivalByType === "Plane, Barcelona Airport (BCN)" ? (
                                                        <a target="_blank" rel="noopener noreferrer" href={event.booking.arrivalByExtraURL}>
                                                            ({event.booking.arrivalByExtra})
                                                        </a>
                                                    ) : null}{" "}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="info" /> {event.booking.refId}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="phone" />{" "}
                                                    <a target="_blank" rel="noopener noreferrer" href={"skype://" + event.customer.phone + ""}>
                                                        {event.customer.phone}
                                                    </a>
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="globe" /> {event.booking.channel}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="users" /> {event.booking.guests}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="language" /> {event?.customer?.language}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 ? (
                                                <p>
                                                    <FontAwesomeIcon icon="hand-holding-usd" /> €{event.booking.balanceDue}
                                                </p>
                                            ) : null}
                                            {event.property.address ? (
                                                <p>
                                                    <FontAwesomeIcon icon="map" />{" "}
                                                    <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(event.property.address) + ""}>
                                                        {event.property.address}
                                                    </a>
                                                    {event.property.floor ? ", " + event.property.floor : null}
                                                    {event.property.door ? ", " + event.property.door : null} {event.property.buzzerInfo ? "(" + event.property.buzzerInfo + ")" : null}{" "}
                                                    {event.property.meetingPoint && event.eventType < 3 ? (
                                                        <span>
                                                            (
                                                            <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(event.property.meetingPoint) + ""}>
                                                                {event.property.meetingPoint}
                                                            </a>
                                                            )
                                                        </span>
                                                    ) : null}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 && event.booking.beds ? (
                                                <p>
                                                    <FontAwesomeIcon icon="bed" /> {event.booking.beds}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 && event.booking.checkinTodo.length ? (
                                                <p>
                                                    <FontAwesomeIcon icon="spinner" /> {event.booking.checkinTodo.join(", ")}
                                                </p>
                                            ) : null}
                                            {event.eventType === 3 ? (
                                                <>
                                                    <p style={{ whiteSpace: "pre-wrap" }}>
                                                        <FontAwesomeIcon icon="tasks" /> {event.task.taskDesc}
                                                    </p>
                                                    <p style={{ whiteSpace: "pre-wrap" }}>
                                                        <FontAwesomeIcon icon="info" /> {event.task.taskNotes}
                                                    </p>
                                                </>
                                            ) : null}
                                            {event.eventType === 3 && event.task.taskType === 2 && event.task.deliveryIsMultiple ? (
                                                <p>
                                                    <FontAwesomeIcon icon="exclamation" /> Multiple apartments
                                                </p>
                                            ) : null}
                                            {event.eventType === 2 && event.booking.deposit ? (
                                                <p>
                                                    <FontAwesomeIcon icon="credit-card" /> {event.booking.deposit}
                                                </p>
                                            ) : null}
                                            {event.eventType === 2 && event.booking.keysGiven ? (
                                                <p>
                                                    <FontAwesomeIcon icon="key" /> {event.booking.keysGiven} keys given
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 && event.booking.notes ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sticky-note" /> {event.booking.notes}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 && event.property.propertyInfo ? (
                                                <p>
                                                    <FontAwesomeIcon icon="exclamation" /> {event.property.propertyInfo}
                                                </p>
                                            ) : null}
                                            {event.eventType < 3 && event.property.cleaningCompany ? (
                                                <p>
                                                    <FontAwesomeIcon icon="broom" /> {event.property.cleaningCompany}
                                                </p>
                                            ) : null}
                                            {event.eventType === 3 && event.task.createdBy ? (
                                                <p>
                                                    <FontAwesomeIcon icon="user" /> {event.task.createdBy}
                                                </p>
                                            ) : null}
                                            {event.eventType === 1 && event.booking.departureDate ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sign-out-alt" /> {moment(event.booking.departureDate).format("DD/MM/YYYY HH:mm")}
                                                </p>
                                            ) : null}
                                            {event.eventType === 2 && event.property.nexteventTime ? (
                                                <p>
                                                    <FontAwesomeIcon icon="sign-in-alt" />{" "}
                                                    <span className={moment(event.property.nexteventTime).isBefore(moment({ hour: 15, minute: 0 })) ? "error" : ""}>
                                                        {moment(event.property.nexteventTime).format("DD/MM/YYYY HH:mm")} ({event.property.nextisAutoCheckin ? "Auto " : null}Checkin)
                                                    </span>
                                                </p>
                                            ) : null}
                                        </div>
                                    )}

                                    {event.property.isFirstTime === 1 && this.state.accessLevel > 2 && (
                                        <p className="error">This is your first checkin in this apartment, please read the "Special Info"</p>
                                    )}

                                    {event.eventType === 1 && this.state.accessLevel > 0 && this.state.accessLevel < 3 && (
                                        <CardActions>
                                            <RaisedButton
                                                label={"Pre Checkin"}
                                                onClick={() => {
                                                    this.props.history.push("/precheckin/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            <RaisedButton label={"Inventory"} onClick={() => window.open("https://inventoryapp.stay-u-nique.com/#/find/" + event.property.propertyId)} />
                                        </CardActions>
                                    )}

                                    {event.eventType === 1 && this.state.accessLevel > 2 && (
                                        <CardActions>
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Edit"}
                                                onClick={() => {
                                                    this.props.history.push("/checkin/edit/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Pre Checkin"}
                                                onClick={() => {
                                                    this.props.history.push("/precheckin/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Checkin"}
                                                onClick={() => {
                                                    this.props.history.push("/checkin/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            {event.booking.channelId !== 7 ? (
                                                <RaisedButton
                                                    style={{ marginBottom: 10 }}
                                                    label={"Contract"}
                                                    onClick={() => {
                                                        this.props.history.push("/contract/" + event.eventId.substr(2) + "");
                                                    }}
                                                />
                                            ) : null}
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Links"}
                                                onClick={() => {
                                                    this.props.history.push("/links/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={event.booking.atApt === 0 ? "At Apartment" : "Remove At Apartment"}
                                                onClick={() => {
                                                    this.markAtApartment(event.eventId.substr(2));
                                                }}
                                            />

                                            <RaisedButton
                                                primary={true}
                                                label={"Issue"}
                                                onClick={() => {
                                                    this.props.history.push("/issue/add/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            {event.property.autoCheckIn === 1 && (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Open Door"}
                                                    onClick={() => {
                                                        this.openDoorDialog(event.property.propertyId);
                                                    }}
                                                />
                                            )}
                                        </CardActions>
                                    )}

                                    {event.eventType === 2 && this.state.accessLevel > 0 && this.state.accessLevel < 3 && (
                                        <CardActions>
                                            {event.property.nexteventId ? (
                                                <RaisedButton
                                                    label={"Pre Checkin"}
                                                    onClick={() => {
                                                        this.props.history.push("/precheckin/" + event.property.nexteventId + "");
                                                    }}
                                                />
                                            ) : null}
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Inventory"}
                                                onClick={() => window.open("https://inventoryapp.stay-u-nique.com/#/find/" + event.property.propertyId)}
                                            />
                                        </CardActions>
                                    )}

                                    {event.eventType === 2 && this.state.accessLevel > 2 && (
                                        <CardActions>
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Edit"}
                                                onClick={() => {
                                                    this.props.history.push("/checkout/edit/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            {event.property.nexteventId ? (
                                                <RaisedButton
                                                    style={{ marginBottom: 10 }}
                                                    label={"Pre Checkin"}
                                                    onClick={() => {
                                                        this.props.history.push("/precheckin/" + event.property.nexteventId + "");
                                                    }}
                                                />
                                            ) : null}
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Checkout"}
                                                onClick={() => {
                                                    this.props.history.push("/checkout/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            <RaisedButton
                                                style={{ marginBottom: 10 }}
                                                label={"Links"}
                                                onClick={() => {
                                                    this.props.history.push("/links/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            <RaisedButton
                                                primary={true}
                                                style={{ marginBottom: 10 }}
                                                label={"Checkout App"}
                                                onClick={() => {
                                                    window.open("https://checkoutapp.stay-u-nique.com/#/checkout/" + event.property.propertyId);
                                                }}
                                            />
                                            <RaisedButton
                                                primary={true}
                                                style={{ marginBottom: 10 }}
                                                label={"Special Info"}
                                                onClick={() => {
                                                    this.props.history.push("/info/out/" + event.property.propertyId + "");
                                                }}
                                            />
                                            <RaisedButton
                                                primary={true}
                                                label={"Issue"}
                                                onClick={() => {
                                                    this.props.history.push("/issue/add/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            {event.booking.checkoutPdf ? (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Checkout PDF"}
                                                    onClick={() => {
                                                        window.open(event.booking.checkoutPdf);
                                                    }}
                                                />
                                            ) : null}
                                            {event.property.autoCheckIn === 1 && (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Open Door"}
                                                    onClick={() => {
                                                        this.openDoorDialog(event.property.propertyId);
                                                    }}
                                                />
                                            )}
                                        </CardActions>
                                    )}

                                    {event.eventType === 3 && this.state.accessLevel > 2 && (
                                        <CardActions>
                                            <RaisedButton
                                                label={"Edit"}
                                                onClick={() => {
                                                    this.props.history.push("/task/edit/" + event.eventId.substr(2) + "");
                                                }}
                                            />
                                            {event.task.taskType === 1 && event.booking.refId ? (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Open Booking"}
                                                    onClick={() => {
                                                        this.props.history.push("/checkout/" + event.booking.refId);
                                                    }}
                                                />
                                            ) : null}
                                            {event.task.taskType === 2 && event.property.propertyId ? (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Open Inventory"}
                                                    onClick={() => window.open("https://inventoryapp.stay-u-nique.com/#/find/" + event.property.propertyId)}
                                                />
                                            ) : null}
                                            {event.task.taskType === 3 && event.property.propertyId ? (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Open Inventory"}
                                                    onClick={() => window.open("https://inventoryapp.stay-u-nique.com/#/find/" + event.property.propertyId)}
                                                />
                                            ) : null}
                                            {event.property.autoCheckIn === 1 && (
                                                <RaisedButton
                                                    primary={true}
                                                    label={"Open Door"}
                                                    onClick={() => {
                                                        this.openDoorDialog(event.property.propertyId);
                                                    }}
                                                />
                                            )}
                                        </CardActions>
                                    )}
                                </CardText>
                            </Card>
                        );
                    })}

                    <Dialog actions={dialogButtons} modal={false} open={this.state.door_dialog_open} onRequestClose={this.handleCloseDoorDialog}>
                        <p>Please toggle the switch below to confirm you know what you are doing</p>
                        {this.state.lockNotes ? (
                            <p>
                                <span style={{ color: "red" }}>Important:</span> {this.state.lockNotes}
                            </p>
                        ) : null}
                        <Toggle onToggle={this.onToggleConfirm} label="I trust myself" labelPosition="right" />
                    </Dialog>
                </div>
            </div>
        );
    }
}

export default withAuth(EventsList);
