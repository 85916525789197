import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import validate from "./validate";
import RaisedButton from "material-ui/RaisedButton";

import fetchClient from "../../components/utils/fetchClient";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { Toggle } from "redux-form-material-ui";
import FullscreenDialog from "material-ui-fullscreen-dialog";

import { connect } from "react-redux";

class CheckOutForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImage: "",
            modal_open: false
        };
    }

    componentWillMount() {
        this.depositrefuploader = new Uppy({ id: "depref", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "deposits",
                                upload_type: "deposit_ref",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                fetchClient
                    .post("store", { id: self.props.bookingId, type: "deposit_ref", url: uploadURL })
                    .then(function (response) {
                        self.props.change("depositRefund", uploadURL);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });

        this.checkoutpicsuploader = new Uppy({ id: "checkout", autoProceed: true })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "checkoutpics",
                                upload_type: "checkout",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp, uploadURL) => {
                var self = this;
                fetchClient
                    .post("store", { id: self.props.bookingId, type: "checkout", url: uploadURL })
                    .then(function (response) {
                        self.props.change("checkoutPics", [...self.props.checkoutPics, uploadURL]);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
    }

    componentWillUnmount() {
        this.depositrefuploader.close();
        this.checkoutpicsuploader.close();
    }

    showModal(image) {
        if (image) {
            this.setState({ currentImage: image, modal_open: true });
        }
    }

    render() {
        const { handleSubmit, invalid, depositRefund, checkoutPics } = this.props;

        return (
            <form onSubmit={handleSubmit} style={{ marginLeft: 5, marginBottom: 5 }}>
                <div>
                    <Field name="markLinkedAsDone" type="checkbox" component={Toggle} label="Mark linked tasks as done" labelPosition="right" />
                </div>
                <h3>Deposit Refund</h3>

                <DragDrop
                    uppy={this.depositrefuploader}
                    locale={{
                        strings: {
                            dropHereOr: "Upload image or %{browse}",
                            browse: "browse"
                        }
                    }}
                />

                <StatusBar uppy={this.depositrefuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

                {depositRefund ? (
                    <div>
                        <br />
                        <RaisedButton label="View Image" disableTouchRipple={true} disableFocusRipple={true} onClick={() => this.showModal(depositRefund)} />
                    </div>
                ) : null}

                <h3>Checkout pics</h3>

                <DragDrop
                    uppy={this.checkoutpicsuploader}
                    locale={{
                        strings: {
                            dropHereOr: "Upload image or %{browse}",
                            browse: "browse"
                        }
                    }}
                />

                <StatusBar uppy={this.checkoutpicsuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

                {checkoutPics ? (
                    <div>
                        <br />
                        {checkoutPics.map((image, index) => (
                            <RaisedButton
                                style={{ marginBottom: 10, marginRight: 10 }}
                                onClick={() => this.showModal(image)}
                                key={parseFloat(index + 1)}
                                label={"View Image #" + parseFloat(index + 1) + ""}
                            />
                        ))}{" "}
                    </div>
                ) : null}

                <FullscreenDialog
                    open={this.state.modal_open}
                    onRequestClose={(...args) => {
                        this.setState({ modal_open: false });
                    }}
                    title="View Image"
                >
                    <div style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                        <img style={{ width: "100%", height: "auto" }} src={this.state.currentImage} alt="View" />
                    </div>
                </FullscreenDialog>

                <div>
                    <div style={{ marginTop: 5 }}>
                        <RaisedButton label="Next" disableTouchRipple={true} disableFocusRipple={true} primary={true} disabled={invalid} type="submit" style={{ marginLeft: 10 }} />
                    </div>
                </div>
            </form>
        );
    }
}

CheckOutForm = reduxForm({
    form: "checkoutform",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(CheckOutForm);

const selector = formValueSelector("checkoutform");

CheckOutForm = connect(state => {
    const { depositRefund, checkoutPics, bookingId } = selector(state, "depositRefund", "checkoutPics", "bookingId");
    return {
        depositRefund,
        checkoutPics,
        bookingId
    };
})(CheckOutForm);

export default CheckOutForm;
